<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<div v-if="unit.standards.length==0" class="pa-2 text-center"><i>This unit does not have any standards specified.</i></div>
	<div class="mx-1 my-2" v-if="unit.standards.length>0">
		<div class="d-flex">
			<div class="k-unit-view-header ml-2">Standards</div>
			<v-spacer/>
			<v-btn-toggle v-if="assets_showing" dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="collection_view_mode" mandatory>
				<v-btn x-small light :value="'tiles'" @click.stop="">Tile View</v-btn>
				<v-btn x-small light :value="'list'" @click.stop="">List View</v-btn>
			</v-btn-toggle>
		</div>
		<div class="k-lp-unit-description mt-2 ml-3 mr-3">
			<div v-if="!standards_tree_loaded" class="pa-4" style="font-size:16px">Loading...</div>
			<div v-for="(o) in unit_standards_tree" class="k-lp-unit-standard" :style="standard_style(o)" :class="standard_class(o)" v-show="standard_showing[o.cfitem.identifier]">
				<div class="k-lp-unit-standard-statement-wrapper">
					<div style="flex:0 0 26px; margin:-1px 0 0 0;">
						<v-icon v-if="o.children.length>0" style="font-size:17px;" color="#666" @click="toggle_children(o)">fa-fw fas {{standard_children_showing[o.cfitem.identifier] ? 'fa-circle-chevron-down' : 'fa-circle-chevron-right'}}</v-icon>
						<v-icon v-else style="font-size:17px;" color="#666">fa-fw fas fa-circle-dot</v-icon>
					</div>
					<div class="k-lp-unit-standard-statement" @click.stop="o.children.length>0?toggle_children(o):show_standard_resources(o)"><b v-if="o.cfitem.humanCodingScheme" style="margin-right:6px" v-html="o.cfitem.humanCodingScheme"></b><span class="k-case-tree-item-statement" v-html="U.marked_latex(o.cfitem.fullStatement)"></span></div>
					<v-spacer/>
					<v-btn icon small color="secondary" style="margin:-2px 6px 0 4px" @click.stop="show_standard_in_satchel(o.cfitem)"><v-icon small>fas fa-map</v-icon></v-btn>
					<!-- <div style="flex:0 0 88px" class="text-center"><v-btn x-small :disabled="!o.branch_assets.length" class="k-tight-btn k-nocaps-btn" color="secondary" :outlined="standard_resources_showing[o.cfitem.identifier]" :xcolor="standard_resources_showing[o.cfitem.identifier]?'primary':'secondary'" @click.stop="show_standard_resources(o)">{{o.branch_assets.length}} {{U.ps('Resource', o.branch_assets.length)}}<v-icon small class="ml-1" :style="standard_resources_showing[o.cfitem.identifier]?'transform:rotate(180deg)':''">fas fa-caret-down</v-icon></v-btn></div> -->
				</div>
				<!-- note that we're relying on a lot of the CollectionResourceFolder styles here -->
				<div v-if="unit.standards_aligned_assets_loaded&&o.branch_assets.length>0&&(o.direct_assets.length>0||!standard_children_showing[o.cfitem.identifier])" class="k-lp-unit-standard-resources" :class="`k-resource-folder-${collection_view_mode}`" :style="o.branch_assets.length==0?'border-width:0!important':''">
				<!-- <div v-if="standard_resources_showing[o.cfitem.identifier]&&unit.standards_aligned_assets_loaded" class="k-lp-unit-standard-resources" :class="`k-resource-folder-${collection_view_mode}`" :style="o.branch_assets.length==0?'border-width:0!important':''"> -->
					<div v-if="o.primary_assets.length>0" style="margin:8px 0; padding-left:8px; color:#444;"><b>Primary Alignments</b> ({{ o.primary_assets.length }})</div>
					<div v-if="o.primary_assets.length>0" class="k-resource-folder-resources-inner">
						<div v-for="(asset) in o.primary_assets" :key="asset.asset_type+'-'+asset.asset_id" :class="`k-resource-folder-resource-container-width-${collection_view_mode=='list'?'full':'half'}`"><div style="height:100%" :class="`k-resource-folder-resource-item-${collection_view_mode=='list'?'full':'half'}-wrapper`">
							<ResourceCollectionItem :item="asset.resource?asset.resource:asset.lesson" 
								:full_width_resource="collection_view_mode=='list'"
								@copy_to_shadow_unit_finish="$emit('copy_to_shadow_unit_finish', $event)"
								@edit_item_saved="edit_item_saved"
								@lesson_shift_update="$emit('lesson_shift_update',$event)"
								@create_activity_from_resource="$emit('create_activity_from_resource',$event)"
							/>
						</div></div>
					</div>

					<div v-if="o.secondary_assets.length>0" :style="o.primary_assets.length>11110?'margin:16px 0 8px 0':'margin:8px 0'" style="margin:8px 0; padding-left:8px; color:#444;"><b>Secondary Alignments</b> ({{ o.secondary_assets.length }})<v-btn class="ml-3" x-small outlined color="primary" @click="show_secondary_resources(o)">{{ standard_resources_showing[o.cfitem.identifier]?'Hide':'Show' }}<v-icon small class="ml-2">fas fa-caret-{{ standard_resources_showing[o.cfitem.identifier]?'up':'down' }}</v-icon></v-btn></div>
					<div v-if="o.secondary_assets.length>0" v-show="standard_resources_showing[o.cfitem.identifier]" class="k-resource-folder-resources-inner">
						<div v-for="(asset) in o.secondary_assets" :key="asset.asset_type+'-'+asset.asset_id" :class="`k-resource-folder-resource-container-width-${collection_view_mode=='list'?'full':'half'}`"><div style="height:100%" :class="`k-resource-folder-resource-item-${collection_view_mode=='list'?'full':'half'}-wrapper`">
							<ResourceCollectionItem :item="asset.resource?asset.resource:asset.lesson" 
								:full_width_resource="collection_view_mode=='list'"
								@copy_to_shadow_unit_finish="$emit('copy_to_shadow_unit_finish', $event)"
								@edit_item_saved="edit_item_saved"
								@lesson_shift_update="$emit('lesson_shift_update',$event)"
								@create_activity_from_resource="$emit('create_activity_from_resource',$event)"
							/>
						</div></div>
					</div>
					<div v-if="o.direct_assets.length < o.branch_assets.length" style="flex:0 0 100%; cursor:pointer" class="text-center" :class="o.direct_assets.length>0?'my-2':''" @click="toggle_children(o,true)"><i>See child standards for {{o.branch_assets.length-o.direct_assets.length}} {{o.direct_assets.length>0 ? 'additional ': ''}}content {{U.ps('item', o.branch_assets.length-o.direct_assets.length)}}</i><v-icon v-if="!standard_children_showing[o.cfitem.identifier]" small class="ml-2">fas fa-caret-{{standard_children_showing[o.cfitem.identifier]?'up':'down'}}</v-icon></div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="user_can_edit_unit" class="text-center mt-4">
		<v-btn small class="k-edit-btn xk-tight-btn xk-nocaps-btn mx-1 mb-3 elevation-0" color="#ddd" @click="$emit('edit_unit')"><v-icon class="mr-2" small>fas fa-edit</v-icon>Edit Unit</v-btn>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResourceCollectionItem from '../resources/ResourceCollectionItem'

export default {
	components: { ResourceCollectionItem },
	props: {
		lp: { type: Object, required: true },
		unit: { type: Object, required: true },
		is_collection_admin: { type: Boolean, required: true },
		search_results_units: { required: true },
		search_results_items: { required: true },
	},
	data() { return {
		standards_tree_loaded: false,
		max_level: 0,
		// tried_to_load_lsdoc_list: false,
		// tried_to_load_framework: false,
	}},
	computed: {
		...mapState(['user_info', 'my_lessons', 'my_resources']),
		standard_showing() { return this.unit.standard_showing },
		standard_children_showing() { return this.unit.standard_children_showing },
		standard_resources_showing() { return this.unit.standard_resources_showing },
		is_default_collection() { return (this.lp.course_code == 'default') },
		user_can_edit_unit() {
			return (this.is_collection_admin && !this.is_default_collection)
		},
		unit_standards_tree() {
			this.max_level = 0
			// Note: we used to load the framework from SuitCASE here; but as of 10/2024 that's no longer necessary, because we're keeping the standards in synch with SuitCASE on the back end
			let top_items = []

			// start with all items that aren't children of other items, in the order they were loaded
			let last_child_of = ''
			let all_top_level_are_siblings = true
			for (let standard of this.unit.standards) {
				if (empty(standard.childOf) || !this.unit.standards.find(x=>x.identifier == standard.childOf)) {
					top_items.push({standard:standard, children:[]})
					if (empty(last_child_of)) last_child_of = standard.childOf
					else if (standard.childOf != last_child_of) all_top_level_are_siblings = false
				}
			}

			// if top_items items are all children of the same item, order by sequenceNumber
			if (!empty(last_child_of) && all_top_level_are_siblings) {
				top_items.sort((a,b)=>a.standard.sequenceNumber - b.standard.sequenceNumber)
			}

			// recursive fn for creating a tree structure for the standards
			let add_descendents = (parent) => {
				for (let standard of this.unit.standards) {
					if (standard.childOf == parent.standard.identifier) {
						parent.children.push({standard:standard, children:[]})
					}
				}
				// sort by sequenceNumber
				parent.children.sort((a,b)=>a.standard.sequenceNumber - b.standard.sequenceNumber)

				// add descendents of children
				for (let child of parent.children) {
					add_descendents(child)
				}
			}

			// now for each item in top_items, add its descendents
			for (let o of top_items) {
				add_descendents(o)
			}

			let arr = []

			let add_item = (parent, level) => {
				// do some calculations while we're going through all the items here...
				if (level > this.max_level) this.max_level = level

				// not exactly sure why we have both standard_showing and standard_children_showing...
				
				// reveal all standards by default...
				// if (this.standard_children_showing[parent.standard.identifier] == undefined) this.$set(this.standard_children_showing, parent.standard.identifier, true)
				// if (this.standard_showing[parent.standard.identifier] == undefined) this.$set(this.standard_showing, parent.standard.identifier, true)

				// or only reveal the first X levels
				let levels_to_show = 2
				if (this.standard_children_showing[parent.standard.identifier] == undefined) this.$set(this.standard_children_showing, parent.standard.identifier, (level < levels_to_show))
				if (this.standard_showing[parent.standard.identifier] == undefined) this.$set(this.standard_showing, parent.standard.identifier, (level <= levels_to_show))

				// construct the parent items
				let primary_assets = []
				let p = {
					cfitem: parent.standard, 
					children: parent.children,
					// start this item's assets list with the assets tied directly to this standard
					direct_assets: this.unit.standards_aligned_assets.filter(x=>{
						if (x.cfitem_identifier != parent.standard.identifier) return false

						// if we have search results, only count items that match search
						if (this.search_results_items.length > 0) {
							// if this unit doesn't have any matches, don't even need to check -- we won't be including this item
							if (!this.search_results_units?.includes(this.unit)) return false

							// if the search_results_items array doesn't include this item, don't include it
							if (!this.search_results_items.find(sri=>{
								// currently all lesson_ids are integers and all resources and activities are guids, so we don't have to worry about checking the type
								return sri.item_id == x.asset_id
							})) return false
						}

						// if we've loaded the full assets, check for primary assets
						if (this.unit.standards_aligned_assets_loaded) {
							// some resources have alignments marked as primary, but some resources have 8 or more "primary" alignments, which doesn't seem right...
							if (false && x.is_primary) {
								// console.log('  found primary alignment')
								primary_assets.push(x)
							} else {
								// ... or if this asset-standard alignment is the very first one listed for the asset
								// (in the imported data from old ALEX, many assets have nothing listed as the primary alignment)
								let asset = x[x.asset_type]
								if (!asset) console.warn('problem', x.asset_type, x)
								else if (asset.standards[0]?.identifier == parent.standard.identifier) {
									// console.log('  adding to primary because this is the first standard listed')
									primary_assets.push(x)
								}
							}
						}
						// if we get to here include the item
						return true
					}),
					level: level,
				}
				// console.log(`found ${primary_assets.length} primary assets`)
				p.primary_assets = primary_assets
				p.secondary_assets = []
				p.branch_assets = p.direct_assets.concat([])
				arr.push(p)

				// add children
				for (let child of parent.children) {
					let cr = add_item(child, level+1)
					// add child's branch_assets to parent's branch_assets, but don't add the same asset twice
					for (let asset of cr.branch_assets) {
						if (!p.branch_assets.find(x=>x.asset_type==asset.asset_type && x.asset_id==asset.asset_id)) {
							p.branch_assets.push(asset)
						}
					}
				}

				// if standards_aligned_assets_loaded...
				if (this.unit.standards_aligned_assets_loaded) {
					// get the "secondary" alignments -- everything for the branch that isn't primary. this will include assets aligned to an item's children
					for (let a of p.direct_assets) {
						if (!p.primary_assets.includes(a)) p.secondary_assets.push(a)
					}

					let sort_fn = (a,b) => {
						// lessons, then sparkls, then other resources
						if (a.asset_type == 'lesson' && b.asset_type != 'lesson') return -1
						if (b.asset_type == 'lesson' && a.asset_type != 'lesson') return 1
						
						if (a.asset_type == 'sparkl' && b.asset_type != 'sparkl') return -1
						if (b.asset_type == 'sparkl' && a.asset_type != 'sparkl') return 1

						// then by name/description
						if (a.asset_type == 'lesson') {
							return U.natural_sort(a.lesson.lesson_title, b.lesson.lesson_title)
						} else {
							return U.natural_sort(a.resource.description, b.resource.description)
						}
					}
					
					p.primary_assets.sort(sort_fn)
					p.secondary_assets.sort(sort_fn)
				}
				
				return p
			}
			for (let item of top_items) add_item(item, 0)

			this.standards_tree_loaded = true
			return arr

			// old algorithm that simulates children with hcs matching
			// let arr = []
			// let current_parent = null
			// let current_parent_hcs = ''
			// let standard_children_showing = {}

			// for (let ust of this.unit.standards) {
			// 	let hcs = ust.humanCodingScheme
			// 	standard_children_showing[ust.identifier] = true

			// 	if (!empty(current_parent_hcs) && hcs?.indexOf(current_parent_hcs) > -1) {
			// 		current_parent.children.push(ust)
			// 		continue
			// 	}

			// 	let o = {
			// 		cfitem: ust,
			// 		children: [],
			// 	}
			// 	arr.push(o)
			// 	current_parent = o
			// 	current_parent_hcs = hcs

			// }

			// return arr
		},
		collection_view_mode: {
			get() {
				if (this.lp.collection_type === 'pd') return 'list'
				return this.$store.state.lst.collection_view_mode
			},
			set(val) { this.$store.commit('lst_set', ['collection_view_mode', val]) }
		},
		assets_showing() {
			for (let item of this.unit_standards_tree) {
				if (item.branch_assets.length > 0) return true
			}
			return false
		},
	},
	watch: {
		unit: { immediate: true, handler() {
			// load the unit's standards_aligned_assets if needed
			if (!this.unit.standards_aligned_assets_loaded) {
				this.unit.get_standards_aligned_assets()
			}
		}}
	},
	created() {
	},
	mounted() {
		vapp.collection_unit_standards_component = this
	},
	methods: {
		toggle_children(o, val) {
			let identifier = o.cfitem ? o.cfitem.identifier : o.standard.identifier

			// by default we toggle, but allow val to be specified directly
			if (typeof(val) != 'boolean') val = !this.standard_children_showing[identifier]

			// if new val is false, hide children, as well as their grandchildren
			if (!val) {
				for (let child of o.children) {
					// have to recurse first here, before we change standard_showing/standard_children_showing for the clicked item
					this.toggle_children(child, false)

					let ss = object_copy(this.standard_showing)
					ss[child.standard.identifier] = false
					this.$store.commit('set', [this.unit, 'standard_showing', ss])
				}

			// else show children
			} else {
				let ss = object_copy(this.standard_showing)
				for (let child of o.children) ss[child.standard.identifier] = true
				this.$store.commit('set', [this.unit, 'standard_showing', ss])
			}

			// set scs for the o item
			let scs = object_copy(this.standard_children_showing)
			scs[identifier] = val
			this.$store.commit('set', [this.unit, 'standard_children_showing', scs])
		},

		standard_style(o) {
			let s = ''
			s += `padding-left:${8+o.level*20}px;`

			// if (this.max_level == 0) bcolors = ['#fff']
			// else if (this.max_level == 1) bcolors = ['#eee', '#fff']
			// else if (this.max_level == 2) bcolors = ['#ddd', '#eaeaea', '#fff',]
			// else bcolors = ['#ddd', '#eee', '#f8f8f8', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']
			// s += `background-color:${bcolors[o.level]};`

			return s
		},

		standard_class(o) {
			let bcolors
			if (this.max_level == 0) bcolors = [0]
			else if (this.max_level == 1) bcolors = [0, 4]
			else if (this.max_level == 2) bcolors = [0, 4, 5]
			else if (this.max_level == 3) bcolors = [0, 3, 4, 5]
			else if (this.max_level == 4) bcolors = [0, 2, 3, 4, 5]
			else if (this.max_level == 5) bcolors = [0, 2, 3, 4, 5, 6]
			else if (this.max_level == 6) bcolors = [0, 2, 3, 4, 5, 6, 7]
			else bcolors = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

			return `k-case-tree-item-type-color-${bcolors[o.level]}`
		},

		show_standard_in_satchel(case_item) {
			// show the framework, highlight this item, and send in selected_items and limit_to_selected_items if there
			let data = { 
				framework_identifier: case_item.framework_identifier, 
				item_identifier: case_item.identifier, 
				no_framework_reset: true,
			}

			if (!data.framework_identifier) {
				// if case_item doesn't have a framework_identifier, it was probably selected before we started recording this.
				// so trace up through parents looking for a case_framework_identifier value; we should find it in in CourseView
				let parent = this.$parent
				while (parent) {
					if (parent.case_framework_identifier) { data.framework_identifier = parent.case_framework_identifier; break; }
					parent = parent.$parent
				}
			}

			// add all unit standards as selected_items
			if (this.unit.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.unit.standards) data.selected_items.push(standard.identifier)
				data.limit_to_selected_items = 'only'
			}

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})
		},

		show_secondary_resources(o) {
			let srs = object_copy(this.standard_resources_showing)
			srs[o.cfitem.identifier] = !srs[o.cfitem.identifier]
			this.$store.commit('set', [this.unit, 'standard_resources_showing', srs])
		},

		// this might not be used anymore...
		show_standard_resources(o) {
			console.log(o)
			// if we don't already have it, get the full list of assets for the unit
			if (!this.unit.standards_aligned_assets_loaded) {
				this.unit.get_standards_aligned_assets()
			}

			// reveal the resources for the standard
			let srs = object_copy(this.standard_resources_showing)
			srs[o.cfitem.identifier] = !srs[o.cfitem.identifier]
			this.$store.commit('set', [this.unit, 'standard_resources_showing', srs])

			// as soon as the assets are loaded from the service, they'll appear under each standard

			// if children have additional assets, show children
			if (o.branch_assets.length > o.direct_assets.length) this.toggle_children(o, true)
		},

		edit_item_saved(args) {
			// this is called from ResourceCollectionItem; see the original version of this fn in CollectionResourceFolder
			// args will include `type` and `updated_resource` or `updated_lesson`
			console.log('edit_item_saved (CollectionUnitStandards)', args)

			if (args.type == 'resource' || args.type == 'activity') {
				// note that sparkl activities are saved as resources
				let updated_resource = new Resource(args.updated_resource)

				// we should only be editing things in my_resources from here; try to update there
				let index = this.my_resources.findIndex(x=>x.resource_id == updated_resource.resource_id)
				if (index != -1) {
					console.log('committing updated resource to my_resources')
					this.$store.commit('set', [this.my_resources, 'SPLICE', index, updated_resource])
				}

			} else if (args.type == 'lesson') {
				let updated_lesson = args.updated_lesson

				// apply *_showing values from edited_lesson if we have it
				if (args.edited_lesson) {
					updated_lesson.resources_showing = args.edited_lesson.resources_showing
					updated_lesson.standards_showing = args.edited_lesson.standards_showing
					updated_lesson.student_description_showing = args.edited_lesson.student_description_showing
					for (let i = 0; i < args.edited_lesson.lesson_plan.length; ++i) {
						updated_lesson.lesson_plan[i].lc_showing = args.edited_lesson.lesson_plan[i].lc_showing
					}
				}

				// we should only be editing things in my_lessons from here; try to update there
				let index = this.my_lessons.findIndex(x=>x.lesson_id == updated_lesson.lesson_id)
				if (index != -1) {
					this.$store.commit('set', [this.my_lessons, 'SPLICE', index, new Lesson(updated_lesson)])
				}
			}
		},
	}
}
</script>

<style lang="scss">
.k-lp-unit-standard {
	max-width:900px;
	margin:0 auto;
	padding-top:4px;
	padding-bottom:4px;
	padding-right:12px;
	font-size:15px;
	line-height:19px;
	border-top:1px solid #bbb;
	border-bottom:1px solid #bbb;
	margin-bottom:-1px;
}

.k-lp-unit-standard-top-category {
	font-weight:bold;
	color:$v-deep-orange-darken-4;
	margin-top:8px;
}

.k-lp-unit-standard-statement-wrapper {
	display:flex;
	align-items: flex-start;
}

.k-lp-unit-standard-statement {
	cursor:pointer;
}
.k-lp-unit-standard-statement:hover {
	text-decoration:underline;
}

.k-lp-unit-standard-resources {
	background-color:#fff;
	font-size:14px;
	margin-left: 16px;
	margin-right:-2px;

	.k-resource-folder-resource-container-width-half {
		padding:2px 4px;
	}
}

.k-lp-unit-standard-resources.k-resource-folder-list {
	border:1px solid #999;
	margin-top:4px;
	margin-bottom:4px;
	border-radius:6px;
	overflow:hidden;
}

// from Satchel
.k-case-tree-item-statement {
	// see also k-case-tree-item-association-title in CASEItemAssociations
	display:inline;
	p:first-of-type {
		display:inline;
	}

	ul, ol {
		margin-top:4px;
	}

	table {
		border-collapse:collapse;
		margin:4px 0;
	}
	th, td {
		border:1px solid #ddd;
		padding:4px;
	}
	th {
		background-color:#f8f8f8;
	}
	td {
		background-color:#fff;
	}
	code {
		// display:inline-block;
		background-color:transparent!important;	// vuetify wants to use a light gray background color
		padding:0 2px!important;
		font-size:0.92em;	// this makes it a little bigger than what it would be otherwise
	}
}

.k-lp-unit-standard-children {
	margin-left:32px;
	margin-bottom:8px;
}

// from Satchel
.k-case-tree-item-type-color-0 { background-color: $v-indigo-lighten-5; }
.k-case-tree-item-type-color-1 { background-color: $v-purple-lighten-5; }
.k-case-tree-item-type-color-2 { background-color: $v-blue-lighten-5; }
.k-case-tree-item-type-color-3 { background-color: $v-teal-lighten-5; }
.k-case-tree-item-type-color-4 { background-color: $v-lime-lighten-5; }
.k-case-tree-item-type-color-5 { background-color: $v-orange-lighten-5; }
.k-case-tree-item-type-color-6 { background-color: $v-brown-lighten-5; }
.k-case-tree-item-type-color-7 { background-color: $v-red-lighten-5; }
.k-case-tree-item-type-color-8 { background-color: $v-deep-purple-lighten-5; }
.k-case-tree-item-type-color-9 { background-color: $v-light-blue-lighten-5; }
.k-case-tree-item-type-color-10 { background-color: $v-green-lighten-5; }
.k-case-tree-item-type-color-11 { background-color: $v-yellow-lighten-5; }
.k-case-tree-item-type-color-12 { background-color: $v-deep-orange-lighten-5; }
</style>
