<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="d-flex justify-center align-stretch">
	<div class="k-main-collection">
		<div class="d-flex">
			<h2 class="k-main-collection-header k-page-title d-flex mb-4"><v-icon large color="primary" class="mr-4" style="margin-top:4px">fas fa-cubes-stacked</v-icon><b>My Content Collections</b></h2>
			<v-spacer/>
			<v-btn v-if="signed_in" color="secondary" class="k-tight-btn k-nocaps-btn" @click="go_to_favorites"><v-icon small class="mr-2" >fas fa-cubes-stacked</v-icon><span>Favorite Collections</span></v-btn>
		</div>
		<div v-show="initialized&&custom_tab_content_description" class="k-custom-tab-content-description" :style="site_config.customized_tabs.mycollections.description_style" v-html="custom_tab_content_description"></div>

		<div v-show="initialized">
			<div v-if="!new_lp">
				<!-- we're not currently allowing for studentish roles in cureum, but this is here for when we do... -->
				<div v-if="studentish_role&&my_collection_lps.length==0&&signed_in">You have not subscribed to any content collections.</div>
				<div v-if="!studentish_role&&my_collection_lps.length==0&&signed_in">You have not subscribed to or created any content collections.</div>
				<div v-if="my_collection_lps.length==0&&!signed_in">Sign in to {{site_config.app_name}} to start collecting and creating lesson plans, student activities, and other resources.</div>

				<div v-if="my_collection_lps.length>0" style="width:500px; margin:auto;"><v-text-field v-model="search_string" prepend-inner-icon="fa fa-search" rounded clearable clear-icon="fa fa-times-circle" label="Search collection titles…" single-line hide-details outlined dense background-color="#fff"></v-text-field></div>

				<div class="k-main-collection-body">
					<div v-for="(lp) in my_collection_lps_to_show" @click="go_to_lp(lp)" class="k-elevated k-main-collection-item k-lp-tile" :class="lp_css(lp)" v-html="lp_title(lp)" :style="[lp_style(lp), {'color': U.get_contrast_color(lp.color)}]"></div>
				</div>

			</div>

			<div v-if="!new_lp&&!small_screen&&signed_in" class="mt-4">
				<v-btn large class="k-tight-btn k-nocaps-btn mx-1" @click="enter_subscription_code"><v-icon class="mr-3 ml-6">fas fa-cubes-stacked</v-icon><span class="mr-6">SUBSCRIBE to a Content Collection</span></v-btn>
				<v-btn v-if="!studentish_role" large class="k-tight-btn k-nocaps-btn mx-1" @click="create_new_collection"><v-icon class="mr-3 ml-6">fas fa-cubes-stacked</v-icon><span class="mr-6">CREATE a New Content Collection</span></v-btn>
			</div>

			<CollectionEdit v-if="new_lp" :learning_progression="new_lp" @editor_cancel="new_lp=null" />
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionEdit from '../collections/CollectionEdit'
import MiniNav from '../main/MiniNav'

export default {
	components: { CollectionEdit, MiniNav },
	data() { return {
		initialized: false,
		search_string:'',
		search_terms: [],
		new_lp: null,
	}},
	computed: {
		...mapState(['site_config', 'user_info', 'all_courses_loaded', 'all_courses', 'course_update_trigger']),
		...mapGetters(['small_screen', 'signed_in', 'my_default_collection', 'studentish_role']),
		custom_tab_content_description() { return this.site_config.customized_tabs?.mycollections?.description },
		last_collections_viewed: {
			get() { return this.$store.state.lst.last_collections_viewed },
			set(val) { this.$store.commit('lst_set', ['last_collections_viewed', val]) }
		},
		my_collection_lps() {
			let arr = this.all_courses.filter(lp => {
				// when we first create a new LP to be entered, its title will be false
				if (lp.title == '') return false

				// for inactive lp's, only show to people explicitly authorized as viewers (this includes admins)
				if (lp.active == 'no') {
					if (!lp.user_can_view_lp()) return false
				}

				// we always include the default collection here
				if (lp.lp_id == 1) return true

				// then we also include any collections that are in the 'my' category
				if (lp.collection_type == 'my') return true
				// if (!lp.agency_sanctioned) return true

				return false
			})
			return arr
		},
		my_collection_lps_to_show() {
			let arr = this.my_collection_lps.filter(lp => {
				// if all search_terms appear in the title, return true
				let tlc = lp.title.toLowerCase()
				for (let term of this.search_terms) {
					if (tlc.indexOf(term) == -1) return false
				}

				return true
			})

			arr.sort((a,b)=>{
				// always put my default collection last
				if (a.lp_id == 1) return 1
				if (b.lp_id == 1) return -1

				// default sort by title
				if (a.title < b.title) return -1
				if (b.title < a.title) return 1

				return 0
			})

			return arr
		},
	},
	watch: {
		search_string() {
			if (empty(this.search_string)) {
				this.search_terms = []
			} else {
				// convert string to lower case and split on spaces
				this.search_terms = $.trim(this.search_string).toLowerCase().split(/\s+/)
			}
		}
	},
	created() {
	},
	mounted() {
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses', 'initial').then(()=>{
				this.$nextTick(()=>{
					this.initialize()
				})
			}).catch(()=>{
				console.log('error in get_all_courses')
				this.back_to_classes()
			})
		} else {
			this.initialize()
		}
	},
	methods: {
		initialize() {
			// console.log('MyCollectionsIndex initialized', this.$router.history.current)
			// if the user only has a default collection, go to the default collection here -- if the user isn't studentish
			// NOT FOR NOW; we could bring this back later though
			// if (!this.studentish_role) {
			// 	if (['mycollections', 'welcome'].includes(this.$router.history.current.name) && this.my_collection_lps.length == 1) {
			// 		this.$router.push({ path: this.my_default_collection.vue_route() })
			// 	}
			// }

			this.initialized = true

			// if state.create_my_collection is true, it means that the user opened their window right to the default collection, then clicked to create a new collection (see Collection.vue)
			if (this.$store.state.create_my_collection == true) {
				this.$store.commit('set', ['create_my_collection', false])
				// so call create_new_collection here
				this.create_new_collection()
			}
		},

		go_to_favorites() {
			window.history.replaceState(null, '', '/mycollections')
			if (!this.signed_in) this.$store.commit('lst_set', ['unsigned_index_view_flavor', 'favorites'])
			else this.$store.commit('lst_set', ['my_index_view_flavor', 'favorites'])
		},

		lp_css(lp) {
			// let s = vapp.color_from_number(lp.course_code)
			// let s = U.subject_tile_css(lp)
			let s = ''
			if (lp.title.length > 50) {
				s += ' k-lp-tile-extra-long-title'
			} else if (lp.title.length > 30) {
				s += ' k-lp-tile-long-title'
			}
			if (lp.active == 'no') {
				s += ' k-lp-tile-inactive'
			}
			return s
		},

		lp_title(lp) {
			let s = ''
			s += '<div>'
			const contrast_color = U.get_contrast_color(lp.color)

			// start with icon indicating collection type
			if (lp.collection_type == 'course') s += `<i style="color: ${contrast_color}" class="fas fa-chalkboard"></i>`
			else if (lp.collection_type == 'repo') s += `<i style="color: ${contrast_color}" class="fas fa-diagram-project"></i>`
			else if (lp.collection_type == 'pd') s += `<i style="color: ${contrast_color}" class="fas fa-user-graduate"></i>`
			else s += `<i style="color: ${contrast_color}" class="fas fa-cubes-stacked"></i>`
			// add <wbr> tags after slashes
			s += lp.title.replace(/\//g, '/<wbr>')
				if (lp.active == 'no') {
					s += '<br><div class="red mt-1 mx-auto" style="padding:2px; display:inline-block; font-weight:normal">Inactive</div>'

					// s += ' <b class="red--text">[Inactive]</b>'
				}
			s += '</div>'

			return s
		},
		lp_style(lp) {
			return U.collection_color_style(lp)
		},

		go_to_lp(lp) {
			this.$store.commit('set', ['last_lp_list', 'index'])
			this.$router.push({ path: lp.vue_route() })
		},

		create_new_collection() {
			// lps created from here are definitionally NOT agency_sanctioned, and use the "tree" layout
			this.new_lp = new Learning_Progression({
				subject:'', 
				agency_sanctioned: false, 
				lp_layout: 'tree',
				collection_type: 'my',
				active: 'yes',
				use_unit_numbers: false,
				units: [new LP_Unit()],
			})
		},
		enter_subscription_code() {
			vapp.enter_subscription_code()
		}
	}
}
</script>

<style lang="scss">

</style>
