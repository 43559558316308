<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<div class="k-resource-collection-item elevation-3" :class="card_class" :style="{'background-color': color_for_asset_type}" @click="card_clicked">
	<v-hover v-slot:default="{hover}"><div class="k-resource-collection-item--title">
		<!-- <v-checkbox v-if="item.tcc_folder_id&&in_unit_editor" class="shrink pa-0 d-inline-block" style="margin:-2px -2px -2px 0!important" hide-details v-model="checkbox_checked" @change="checkbox_clicked" @click.stop=""></v-checkbox> -->

		<div class="k-resource-collection-item-type-icon">
			<v-icon :color="icon_color(hover)" small>fas {{icon}}</v-icon>
			<!-- <v-icon :color="hover?'rgba(255,255,255,0)':'rgba(255,255,255,0.8)'" small>fas {{icon}}</v-icon> -->
		</div>

		<a @click.stop="card_clicked" v-html="item_title" class="k-resource-collection-item-title-link"></a>
		<div v-if="item.standards&&item.standards.length>0" v-show="full_width_resource" class="k-resource-collection-item-standards-wrapper" @click.stop="">
			<div v-if="item.standards.length>default_max_standards_showing" style="margin-right:2px;">
				<v-btn x-small text color="#333" v-if="show_all_standards" @click="show_all_standards=false" style="font-size:12px; min-width:20px;" class="px-0"><v-icon style="font-size:10px" x-small>fas fa-chevrons-right</v-icon></v-btn>
				<v-btn x-small text color="#333" class="px-0" v-if="!show_all_standards" @click="show_all_standards=true" style="font-size:12px">+{{item.standards.length-default_max_standards_showing}}<v-icon style="margin-left:3px; font-size:10px;">fas fa-chevrons-left</v-icon></v-btn>
			</div>
			<CASEItemBtn v-for="(s, i) in item.standards" v-show="i<default_max_standards_showing||show_all_standards" :key="s.identifier" x-small btn_color="#444" :outer_class="'ml-1'" :item="s" :selected_items="item.standards" :framework_identifier="s.framework_identifier" satchel_determines_framework_id="yes" star_primary_alignments="false" />
		</div>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-on="on" class="k-resource-collection-item-search-result" @click.stop="$emit('clear_collection_last_search_results')" v-show="in_last_search_results">fas fa-search</v-icon></template><div style="text-align:center; font-size:12px; line-height:14px;">This item was found<br>in your last resource search<br>(click to clear)</div></v-tooltip>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-visible="hover" v-on="on" v-if="resource_is_unshowable_msg" x-small color="#999" class="mx-1">fas fa-ban</v-icon></template><div v-html="resource_is_unshowable_msg"></div></v-tooltip>

		<!-- In situations where you can remove the item, we want to show the item's date. this includes the default collection, a shadow unit item, and when you're editing a unit -->
		<div v-if="(viewing_my_default_collection||viewing_my_shadow_unit||option_availability.remove||from_archived)&&full_width_resource&&created_date" class="k-resource-collection-item-created-date" v-html="created_date"></div>

		<div v-if="!from_pd_resource_popup_collection" class="k-resource-collection-item-menu-btn"><v-menu offset-y bottom right><template v-slot:activator="{on}"><v-btn v-visible="hover||full_width_resource" v-on="on" icon small color="#000" @click="mark_as_last_viewed_resource"><v-icon small>fas fa-ellipsis-v</v-icon></v-btn></template>
			<v-list dense>
				<v-list-item @click="card_clicked" style="border-bottom:1px solid #ccc"><v-list-item-icon><v-icon small color="#000">fas {{icon}} fa-beat</v-icon></v-list-item-icon><v-list-item-title><b>Open {{item_noun(true)}}</b></v-list-item-title></v-list-item>

				<v-list-item v-if="option_availability.open_in_new_window" @click="open_resource_in_new_window"><v-list-item-icon><v-icon small>fas fa-arrow-up-right-from-square</v-icon></v-list-item-icon><v-list-item-title>Open {{item_noun(true)}} in a new window</v-list-item-title></v-list-item>

				<v-list-item v-if="option_availability.copy_link" @click="copy_link_to_clipboard"><v-list-item-icon><v-icon small>fas fa-link</v-icon></v-list-item-icon><v-list-item-title>Copy {{item_noun(true)}} link to clipboard</v-list-item-title></v-list-item>

				<v-list-item v-if="option_availability.copy_item_for_my_use" @click="copy_to_my_content"><v-list-item-icon><v-icon small color="green darken-2">fas fa-cubes-stacked</v-icon></v-list-item-icon>
					<v-list-item-title v-if="is_non_sparkl_resource">Copy {{item_noun(true)}} for my use</v-list-item-title>
					<v-list-item-title v-if="!is_non_sparkl_resource&&!manage_assignments">Copy {{item_noun(true)}} for my use</v-list-item-title>
					<v-list-item-title v-if="!is_non_sparkl_resource&&manage_assignments">Use this {{item_noun(true)}} with my students</v-list-item-title>
				</v-list-item>

				<v-list-item v-if="option_availability.create_sparkl_from_resource" @click="assign_resource"><v-list-item-icon><v-icon small color="green">fas fa-star</v-icon></v-list-item-icon><v-list-item-title>Create a new Student Activity with this resource</v-list-item-title></v-list-item>

				<v-list-item v-if="option_availability.add_to_gc" @click="add_to_gc"><v-list-item-icon><img src="./../../images/google_classroom_logo.png" style="height:18px;margin-top:3px;margin-left:-4px;border-radius:3px;"></v-list-item-icon><v-list-item-title>Add to Google Classroom</v-list-item-title></v-list-item>

				<v-list-item v-if="option_availability.import_to_district" @click="share_item('district')"><v-list-item-icon><v-icon small color="indigo">fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Import {{item_noun(true)}} to HenryConnects</v-list-item-title></v-list-item>

				<v-list-item v-if="option_availability.share" @click="share_item"><v-list-item-icon><v-icon small color="purple">fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Share {{item_noun(true)}} with other educators</v-list-item-title></v-list-item>

				<v-list-item v-if="item_copy_for_user" class="align-start"><v-list-item-icon style="margin-top:-4px"><v-icon color="green darken-2" small>fas fa-cubes-stacked</v-icon></v-list-item-icon><v-list-item-title><i>You have copied this {{item_noun(true)}} for your use.</i>
					<div v-if="option_availability.show_original" class="mt-2 mb-1" style="cursor:pointer" @click="show_original"><v-icon color="green darken-2" class="mr-2" small>fas fa-clock-rotate-left</v-icon>Show original item</div>
				</v-list-item-title></v-list-item>
				
				<v-list-item v-if="option_availability.add_to_lesson" @click="add_to_open_lesson"><v-list-item-icon><v-icon small color="blue darken-2">fas fa-circle-plus</v-icon></v-list-item-icon><v-list-item-title>Add {{item_noun(true)}} to open Lesson</v-list-item-title></v-list-item>

				<v-list-item v-if="option_availability.edit" @click="edit_item_start"><v-list-item-icon><v-icon small color="blue darken-2">fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit {{item_noun(true)}}</v-list-item-title></v-list-item>

				<v-list-item v-if="option_availability.duplicate" @click="duplicate_item"><v-list-item-icon><v-icon small color="teal">fas fa-copy</v-icon></v-list-item-icon><v-list-item-title>Duplicate {{item_noun(true)}}</v-list-item-title></v-list-item>

				<v-list-item v-if="item.restricted||option_availability.show_restricted_resources_control" @click="edit_item_restriction"><v-list-item-icon><v-icon small :color="item.restricted?'red darken-3':'green darken-3'">fas {{item.restricted?'fa-lock':'fa-lock-open'}}</v-icon></v-list-item-icon><v-list-item-title>
					<span v-if="item.restricted"><b>Restricted</b> to authorized teachers and staff <v-icon v-if="option_availability.show_restricted_resources_control" color="primary" small class="ml-2" style="margin-top:-3px">fas fa-edit</v-icon></span>
					<span v-if="!item.restricted">Not restricted to authorized users <v-icon color="primary" small class="ml-2" style="margin-top:-3px">fas fa-edit</v-icon></span>
				</v-list-item-title></v-list-item>

				<v-list-item v-if="option_availability.remove" @click="$emit('remove_item', item)"><v-list-item-icon><v-icon small color="red darken-2">fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Remove {{item_noun(true)}}</v-list-item-title></v-list-item>

				<v-list-item v-if="option_availability.report_issue" @click="report_issue"><v-list-item-icon><v-icon small class="ml-1">fas fa-exclamation</v-icon></v-list-item-icon><v-list-item-title>Provide feedback about this {{item_noun(true)}}</v-list-item-title></v-list-item>

				<v-list-item v-if="is_sparkl" style="border-top:1px solid #ccc"><v-list-item-title>{{site_config.sparkl_app_name}} Activity ID: A{{item.url}}</v-list-item-title></v-list-item>
				<v-list-item v-if="is_sparkl_bank" style="border-top:1px solid #ccc"><v-list-item-title>{{item_noun(true)}} ID: A{{item.url}}</v-list-item-title></v-list-item>
				<v-list-item v-if="is_lesson" style="border-top:1px solid #ccc"><v-list-item-title>Lesson ID: L{{item_to_show.lesson_id}}</v-list-item-title></v-list-item>
				<v-list-item v-if="is_non_sparkl_resource&&!is_sparkl_bank" style="border-top:1px solid #ccc"><v-list-item-title>Resource ID: R{{item_to_show.resource_id}}</v-list-item-title></v-list-item>
				<v-list-item v-if="from_collection_string" style="border-top:1px solid #ccc"><v-list-item-title>From: {{from_collection_string}}</v-list-item-title></v-list-item>
				<v-list-item v-if="from_other_unit_string" style="border-top:1px solid #ccc"><v-list-item-title>{{from_other_unit_string}}</v-list-item-title></v-list-item>

				<v-list-item v-if="from_archived" @click="$emit('unarchive_item', item)"><v-list-item-icon><v-icon small color="green">fas fa-heart-pulse</v-icon></v-list-item-icon><v-list-item-title>Unarchive this item</v-list-item-title></v-list-item>
			</v-list>
		</v-menu></div>

		<div v-if="item.todo" class="k-resource-collection-item-todo-checkbox">
			<div v-if="!todo_complete" @click.stop="mark_todo_assignment_complete"><v-icon class="k-resource-link-check-box">far fa-square</v-icon></div>
			<div v-else @click.stop="complete_todo_assignment_clicked"><v-icon class="k-resource-link-check-box">fas fa-check-square</v-icon></div>
		</div>
	</div></v-hover>

	<v-dialog v-if="lesson_showing" v-model="lesson_showing" :max-width="item_to_show.lp_variant=='B'?1200:900" persistent scrollable :hide-overlay="lesson_shifted" :content-class="'k-lpe-editor-dialog k-resource-collection-item-lesson-card-dialog'+(lesson_shifted?' k-resource-collection-lesson-item-card-dialog-shifted':'')">
		<v-card class="k-resource-collection-item-lesson-card">
			<div class="d-flex align-center pb-2" :style="item_to_show.lp_variant=='A'?'border-bottom:1px solid #999;':''">
				<v-icon class="mr-2">fas fa-rectangle-list</v-icon>
				<div class="k-lesson-title" style="font-weight:bold" v-html="item_to_show.lesson_title"></div>
				<v-spacer/>
				<div v-if="!($vuetify.breakpoint.xs)" class="k-lesson-course-unit-header" v-html="course_unit_header"></div>
				<div v-if="!($vuetify.breakpoint.xs)" class="mr-2" v-html="date_header"></div>
			</div>
			<LessonView :lesson="item_to_show" :print_lesson_dom_id="print_lesson_dom_id" :show_print_dialog="show_print_dialog" />

			<v-card-actions class="px-1 pt-2 pb-0" :style="item_to_show.lp_variant=='A'?'border-top:1px solid #999;':''">
				<v-tooltip top><template v-slot:activator="{on}"><v-btn v-if="lp_context" v-show="$vuetify.breakpoint.smAndUp" v-on="on" class="ml-1 mr-1" fab x-small dark color="secondary" @click="shift_lesson"><v-icon small>fas {{lesson_shifted?'fa-angles-left':'fa-angles-right'}}</v-icon></v-btn></template>Shift Lesson</v-tooltip>
				<v-tooltip top><template v-slot:activator="{on}"><v-btn v-show="$vuetify.breakpoint.smAndUp" v-on="on" class="ml-1 mr-1" fab x-small dark color="#333" @click="print_lesson"><v-icon small>fas fa-print</v-icon></v-btn></template>Print Lesson</v-tooltip>
				<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-1 mr-1" fab x-small dark color="#333" :href="reslink" target="_blank"><v-icon small>fas fa-arrow-up-right-from-square</v-icon></v-btn></template>Open Lesson in new window</v-tooltip>
				<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-1 mr-1" fab x-small dark color="#333" @click="copy_link_to_clipboard"><v-icon small>fas fa-link</v-icon></v-btn></template>Copy Lesson link to clipboard</v-tooltip>
				<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" v-show="option_availability.copy_item_for_my_use" @click="copy_to_my_content" color="green darken-2" dark class="mx-1" fab x-small><v-icon small>fas fa-cubes-stacked</v-icon></v-btn></template>Copy Lesson Plan for my use</v-tooltip>
				<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" v-show="option_availability.share" class="ml-1 mr-1" fab x-small color="purple" dark @click="share_item"><v-icon>fas fa-share-nodes</v-icon></v-btn></template>Share Lesson with other educators</v-tooltip>
				<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" v-show="option_availability.import_to_district" class="ml-1 mr-1" fab x-small color="indigo" dark @click="share_item('district')"><v-icon>fas fa-share-nodes</v-icon></v-btn></template>Import Lesson to HenryConnects</v-tooltip>
				<v-tooltip top><template v-slot:activator="{on}"><v-btn v-show="option_availability.edit&&$vuetify.breakpoint.smAndUp&&!force_show_original" v-on="on" class="ml-1 mr-1" fab x-small dark color="blue darken-2" @click="edit_item_start"><v-icon small>fas fa-edit</v-icon></v-btn></template>Edit Lesson</v-tooltip>
				<v-tooltip top><template v-slot:activator="{on}"><v-btn v-show="option_availability.remove&&$vuetify.breakpoint.smAndUp&&!force_show_original" v-on="on" class="ml-1 mr-1" fab x-small dark color="red darken-2" @click="$emit('remove_item', item)"><v-icon small>fas fa-trash-alt</v-icon></v-btn></template>Remove Lesson</v-tooltip>

				<v-spacer/>
				<div v-if="item_copy_for_user&&!force_show_original" style="font-size:12px"><v-icon color="green darken-2" small class="mr-1">fas fa-cubes-stacked</v-icon> <i>This lesson has been copied for your use.</i></div>
				<div v-if="force_show_original" style="font-size:12px"><v-icon color="green darken-2" small class="mr-1">fas fa-cubes-stacked</v-icon> <i>You are viewing the original version of a lesson you copied for your use</i></div>
				<v-spacer/>
				<v-btn color="secondary" dark @click="close_lesson"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<LessonPrint v-if="show_print_dialog" :lesson="item" :print_lesson_dom_id="print_lesson_dom_id" @dialog_cancel="show_print_dialog=false" />

	<v-dialog v-if="lesson_being_edited" v-model="lesson_being_edited" :max-width="(lesson_being_edited.lp_variant=='B'||convert_to_llm)?1200:900" persistent scrollable :retain-focus="false"  :hide-overlay="lesson_shifted" :content-class="`k-resource-collection-item-lesson-card-dialog k-lpe-editor-dialog ${lesson_shifted?'k-resource-collection-lesson-item-card-dialog-shifted':''}`">
		<v-card class="k-resource-collection-item-lesson-card">
			<div class="d-flex">
				<v-icon class="mr-2" style="margin-top:-2px;">fas fa-rectangle-list</v-icon>
				<div class="k-lesson-title" style="font-size:18px"><b class="mr-2">Edit Lesson</b> {{lesson_being_edited.lesson_title}}</div>
				<v-spacer/>
				<!-- <v-btn v-if="XXX" class="k-tight-btn k-nocaps-btn" text small color="#444" @click="convert_lesson_to_lee">Use Lesson Builder Assistant… (<b class="red--text text--darken-3">BETA</b>)</v-btn> -->
				<!-- <v-btn v-if="XXX" class="k-tight-btn k-nocaps-btn" text small color="#444" @click="revert_lesson_to_original">Revert to Original Lesson Forma</v-btn> -->
				 <div v-if="lesson_being_edited.lp_variant=='B'" style="cursor:pointer" @click="show_llm_lesson_plan_description">Lesson Plan Companion<v-icon class="k-lpe-dog-icon mx-2" style="margin-top:-5px">fas fa-dog</v-icon>(<b class="red--text text--darken-3">BETA</b>)</div>
			</div>
			<div v-if="lesson_being_edited.lp_variant=='B'||convert_to_llm" class="k-lpe-lesson-edit-outer">
				<LessonEditorEnhanced ref="lesson_editor_component" :original_lesson="lesson_being_edited" :lesson_class="lesson_being_edited_lesson_class" :course_code="course_code" :lp_unit_id="lp_unit_id" :lesson_shifted="lesson_shifted" @shift_lesson="shift_lesson" @edit_lesson_cancel="edit_lesson_cancel" @edit_lesson_saved="edit_lesson_saved" />
			</div>
			<div v-else class="k-lesson-edit-outer">
				<LessonEditor ref="lesson_editor_component" :original_lesson="lesson_being_edited" :lesson_class="lesson_being_edited_lesson_class" :course_code="course_code" :lp_unit_id="lp_unit_id" :lesson_shifted="lesson_shifted" @shift_lesson="shift_lesson" @edit_lesson_cancel="edit_lesson_cancel" @edit_lesson_saved="edit_lesson_saved" />
			</div>
		</v-card>
	</v-dialog>

	<v-dialog v-if="resource_quick_look_showing" v-model="resource_quick_look_showing" :max-width="resource_iframeable?1000:720" :hide-overlay="lp_context&&lp_context.shifted_for_lesson" persistent scrollable :content-class="'k-resource-collection-item-quick-look-card-dialog'+(lp_context&&lp_context.shifted_for_lesson?' k-resource-collection-quick-look-item-card-dialog-shifted':'')">
		<v-card class="k-resource-collection-item-lesson-card" style="overflow:auto;">
			<div class="d-flex">
				<v-icon class="mr-2">fas {{icon}}</v-icon>
				<div class="k-lesson-title" style="font-weight:bold" v-html="item_to_show.description"></div>
				<v-spacer/>
			</div>

			<div v-if="item_to_show.long_description" class="k-resource-collection-item-long-description" v-html="item_to_show.long_description"></div>

			<div v-if="!resource_iframeable" class="text-center my-3"><v-btn color="primary" :href="reslink" target="_blank">Open Resource<v-icon class="ml-2" small>fas fa-arrow-up-right-from-square</v-icon></v-btn></div>

			<div v-if="resource_extensions" class="k-resource-collection-item-extensions" v-html="resource_extensions"></div>

			<!-- <textarea style="height:150px">{{ JSON.stringify(item_to_show.extensions) }}</textarea> -->

			<div v-if="show_inline_document" class="my-2 k-aspect-ratio-box--10-8 k-resource-link-iframe-outer"><div class="k-aspect-ratio-box-inside">
				<iframe :id="'inline_iframe_'+item_to_show.resource_id" :src="inline_document_src" class="k-resource-link-iframe"></iframe>
			</div></div>

			<div v-if="show_inline_html" class="my-2 k-aspect-ratio-box--10-8 k-resource-link-iframe-outer"><div class="k-aspect-ratio-box-inside">
				<iframe :name="'inline_iframe_'+item_to_show.resource_id" class="k-resource-link-iframe"></iframe>
			</div></div>

			<v-card-actions class="px-1 pt-3 pb-0">
				<!-- <v-btn color="primary" dark small @click="$emit('copy_to_my_content', item);lesson_showing=false"><v-icon small class="mr-2">fas fa-plus</v-icon>Add to My Resources</v-btn> -->
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="resource_iframeable" v-on="on" class="ml-1 mr-1" fab x-small dark color="#333" :href="reslink" target="_blank"><v-icon small>fas fa-arrow-up-right-from-square</v-icon></v-btn></template>Open resource in new window</v-tooltip>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-1 mr-1" fab x-small dark color="#333" @click="copy_link_to_clipboard"><v-icon small>fas fa-link</v-icon></v-btn></template>Copy resource link to clipboard</v-tooltip>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="resource_iframeable" v-show="$vuetify.breakpoint.smAndUp" v-on="on" class="ml-1 mr-1" fab x-small dark color="#333" @click="print_resource"><v-icon small>fas fa-print</v-icon></v-btn></template>Print resource</v-tooltip>
				<v-spacer/>
				<v-btn color="secondary" dark @click="hide_quick_look"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<VideoPlayer v-if="show_video_player" :resource="item" @dialog_cancel="show_video_player=false" @video_complete="video_complete" @save_video_progress="save_video_progress" />
	<AssessmentOptions v-if="show_assessment_options" :resource="item" @dialog_cancel="show_assessment_options=false" />

	<ResourceEditor v-if="resource_being_edited" :original_resource="resource_being_edited"
		:lesson="lesson_context" :unit="unit_context" :course="lp_context" :enable_resource_search="enable_resource_search"
		@edit_resource_cancel="edit_resource_cancel"
		@edit_resource_saved="edit_resource_saved"
	/>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LessonView from '../lessons/LessonView'
import LessonPrint from '../lessons/LessonPrint'
import LessonEditor from '../lessons/LessonEditor'
import LessonEditorEnhanced from '../lessons/LessonEditorEnhanced'
import ResourceEditor from '../resources/ResourceEditor'
import VideoPlayer from './VideoPlayer'
import AssessmentOptions from './AssessmentOptions'
import CASEItemBtn from '../standards/CASEItemBtn'

export default {
	name: 'ResourceCollectionItem',
	components: { LessonView, LessonPrint, LessonEditor, LessonEditorEnhanced, ResourceEditor, VideoPlayer, AssessmentOptions, CASEItemBtn },
	props: {
		item: { type: Object, required: true },
		// enable_resource_search is set to true for now, but we will want to change it to false...
		enable_resource_search: { type: Boolean, required: false, default() { return true }},
		large: { type: Boolean, required: false, default() { return false }},

		lesson_context: { type: Object, required: false, default() { return null } },
		unit_context: { type: Object, required: false, default() { return null } },
		lp_context: { type: Object, required: false, default() { return null } },

		full_width_resource: { type: Boolean, required: false, default() { return false } },

		// not currently using the tcc checkboxes on individual resources; only folders
		// show_checkbox: { type: Boolean, required: false, default() { return false } },
		// checkbox_initially_checked: { type: Boolean, required: false, default() { return false } },

		from_pd_resource_popup_collection: { type: Boolean, required: false, default() { return false } },

		from_archived: { type: Boolean, required: false, default() { return false } },
	},
	data() { return {
		in_unit_editor: false,
		in_lesson_editor: false,
		lesson_showing: false,
		resource_quick_look_showing: false,
		show_inline_document: false,
		show_inline_html: false,
		inline_html_always: 'Loading...',
		inline_html_extra: '',
		show_inline_html_extra: false,
		show_print_dialog: false,
		print_lesson_dom_id: U.new_uuid(),
		lesson_shifted: false,
		quick_look_shifted: false,

		// note that force_show_original is implemented differently for lessons and sparkl activities
		force_show_original: false,

		lesson_being_edited: null,
		lesson_being_edited_lesson_class: '',
		resource_being_edited: null,
		convert_to_llm: false,

		sparkl_closed_from_embed: false,

		link_clicked: false,
		show_video_player: false,
		show_assessment_options: false,

		default_max_standards_showing: 1,
		show_all_standards: false,

		// not currently using the tcc checkboxes on individual resources; only folders
		// checkbox_checked: false,
	}},
	computed: {
		...mapState(['user_info', 'user_is_touching', 'my_lessons', 'my_resources', 'my_ca_mappings', 'my_archived_lessons', 'my_archived_resources', 'site_config']),
		...mapGetters(['signed_in', 'studentish_role', 'my_default_collection', 'manage_assignments']),
		course_code() { return this.lp_context ? this.lp_context.course_code : '' },
		lp_unit_id() { return this.unit_context ? this.unit_context.lp_unit_id : 0 },
		viewing_my_default_collection() { return this.lp_context && this.lp_context == this.my_default_collection },
		viewing_my_shadow_unit() { return this.unit_context && this.unit_context.shadows_lp_unit_id != 0 && this.unit_context.shadow_unit_owner_id == this.user_info.user_id },
		collection_type() { return this.lp_context ? this.lp_context.collection_type : '?' },

		use_llm_lesson_plans: {
			get() { return this.$store.state.lst.use_llm_lesson_plans },
			set(val) { this.$store.commit('lst_set', ['use_llm_lesson_plans', val]) }
		},
		enable_llm_lesson_plans() { 
			// if this.site_config.alba_collections is set, limit the enhanced lesson plan tool to these collections
			if (this.site_config.alba_collections && this.site_config.alba_collections.length > 0) {
				if (!this.lp_context || !this.site_config.alba_collections.includes(this.lp_context.course_code)) return false
			}

			return this.site_config.enable_llm_lesson_plans != 'no' 
		},
		use_enhanced_lesson_editor() { 
			// if enable_llm_lesson_plans is 'always', always use enhanced
			if (this.site_config.enable_llm_lesson_plans == 'always') return true

			// else return true if we're allowed to use them or the user chose to use them
			return (this.enable_llm_lesson_plans && this.use_llm_lesson_plans)
		},

		is_lesson() { return !empty(this.item.lesson_title) },
		// Note that sparkl USED TO be deployed as either a resource (for a DOE-provided activity), or as an "activity" object (a user's copy of the activity); now sparkls are always resources
		is_resource() { return !this.is_lesson },
		is_sparkl() { return this.item.type == 'sparkl' },
		is_sparkl_bank() { return this.item.type == 'sparkl_bank' },
		is_non_sparkl_resource() { return this.is_resource && !this.is_sparkl && !this.is_sparkl_bank },

		// this fn checks to see if the use has a copy of the specified item, and returns the copy if so.
		// item_to_show (below) then determines whether to show the original or the copy (if a copy exists)
		// for now at least, we only actually check to see if the lesson/activity is in your default collection or your shadow collection; we don't look in other 'my' collections that you have access to
		item_copy_for_user() {
			// if user isn't signed in, they can't have a copy
			if (!this.signed_in) return null

			// in the following situations we never want to "overwrite" with a copy:

			// if we're viewing the default collection
			if (this.viewing_my_default_collection) return null
			// if we're viewing a shadow unit for the user
			if (this.viewing_my_shadow_unit) return null
			// if we're viewing an item in a non-agency-sanctioned collection, we don't deal with "my" resources
			if (this.lp_context && !this.lp_context.agency_sanctioned) return null
			
			// if we're viewing an item in a non-agency-sanctioned collection and the user is the owner of the collection [replaced with more general clause above]
			// if (this.lp_context && !this.lp_context.agency_sanctioned && this.lp_context.owner_id == this.user_info.user_id) return null

			// for lessons and sparkls, look first in my_x, then in my_default_collection
			if (this.is_lesson) {
				let o = this.my_lessons.find(x=>x.lesson_template_id == this.item.lesson_id)
				if (o) return o

				if (this.my_default_collection) {
					o = this.my_default_collection.units[0].lessons.find(x=>x.lesson_template_id == this.item.lesson_id)
					if (o) return o
				}

				return null

			} else if (this.is_sparkl) {
				let o = this.my_resources.find(x=>x.resource_template_id == this.item.resource_id)
				if (o) return o

				if (this.my_default_collection) {
					o = this.my_default_collection.units[1].resources.find(x=>x.resource_template_id == this.item.resource_id)
					if (o) return o
				}

				return null

			} else {
				// for link resources and sparkl_banks, we don't actually make "deep copies" for users
				return null
			}
		},

		// if the item is a lesson or activity you have a copy of, we want to show/edit your copy in many (but not all) circumstances
		item_to_show() {
			// if we're in the context of the unit editor, always show the original item
			if (this.in_unit_editor) return this.item

			// if force_show_original is true, always use the original item
			if (this.force_show_original) return this.item

			// if we get to here and we have an item_copy_for_user, show it
			return this.item_copy_for_user ?? this.item
		},

		viewing_copy_for_user() {
			// return 
		},

		option_availability() {
			let o = {}

			// copy link/open in new window: everything except sparkl (at least for now) [MC] and the item is not archived
			o.copy_link = (!this.is_sparkl && !this.from_archived)
			o.open_in_new_window = (!this.is_sparkl)

			// students/parents can only do the things above here
			if (this.studentish_role) return o

			// import_to_district: signed in and email domain is in site_config.import_to_district_domains list
			let email_domain = ''
			if (this.signed_in) email_domain = this.user_info.email.replace(/.*@/, '').toLowerCase()
			o.import_to_district = (this.site_config.import_to_district_domains.includes(email_domain))

			// edit: if you're signed in and not in the archived collection
			o.edit = false
			o.remove = false
			if (this.signed_in && !this.from_archived) {
				// for lessons/sparkls...  NOTE: you can't currently add lessons/sparkls to a lesson
				if (this.is_lesson || this.is_sparkl) {
					let editable = false
					// you can always edit things when you're viewing your default collection or you're viewing your own shadow unit
					if (this.viewing_my_default_collection || this.viewing_my_shadow_unit) editable = true
					// you can also edit if the item has been copied for your use, and we're viewing the copy (i.e. the user didn't choose to see the original)
					else if (this.item_copy_for_user && this.item_to_show == this.item_copy_for_user) editable = true
					// if you have rights to edit the collection...
					else if (this.lp_context && this.lp_context.user_is_lp_admin()) {
						// you can edit and delete the item if this is a 'my' collection, or if we're in the unit editor interface
						if (this.collection_type == 'my' || this.in_unit_editor) {
							editable = true
						}
					}

					if (editable) {
						o.edit = true
						o.remove = !this.item_copy_for_user
						// Note: we used to have a restriction that would not let you remove items from a 'my' collection if you're not the creator of the item. We've taken this restriction away now, because the creator can always "unarchive" the item if they need to.
					}

				// else for non-sparkl resources, removal and editing is more separated, because we don't make "deep copies" of resources, and because we could be in the lesson editor...
				} else {
					let editable = false
					// you can always edit things when you're in the unit editor or lesson editor (you wouldn't have gotten to the lesson editor if this isn't the case)
					if (this.in_unit_editor || this.in_lesson_editor) editable = true
					// you can always edit things when you're viewing your default collection or you're viewing your own shadow unit
					else if (this.viewing_my_default_collection || this.viewing_my_shadow_unit) editable = true
					// you can also edit if the item has been copied for your use, and we're viewing the copy (i.e. the user didn't choose to see the original)
					else if (this.item_copy_for_user && this.item_to_show == this.item_copy_for_user) editable = true
					// if you have rights to edit the collection...
					else if (this.lp_context && this.lp_context.user_is_lp_admin()) {
						// you can edit and delete the item if this is a 'my' collection (or if we're in the unit editor interface, which would have gotten caught in the clause above)
						if (this.collection_type == 'my') editable = true
					}

					if (editable) {
						// you're always allowed to remove
						o.remove = true

						// you're allowed to edit if you created the resource
						if (this.item.creator == this.user_info.user_id) o.edit = true
						// or if you have edit rights to the collection, this isn't a 'my' collection, and this isn't the lesson editor
						if ((this.lp_context && this.lp_context.user_is_lp_admin()) && this.collection_type != 'my' && !this.in_lesson_editor) o.edit = true
						// (in a 'my' collection, we don't want you editing agency-sanctioned resources, or resources created by other people. we allow editing resources in an agency-sanctioned collection because there, the person responsible for editing the items in the collection might change over time)
					}
				}

				// if we're viewing the default collection, don't allow removing items that are actually parts of other collections
				// if (o.remove && this.viewing_my_default_collection) {
				// 	// so if we find a my_ca_mapping with this asset_id that doesn't have the default_collection_id, remove is false (but you may still be able to edit)
				// 	let default_collection_id = this.my_default_collection && this.my_default_collection.default_collection_id_for_collection_asset_mapping()
				// 	if (this.my_ca_mappings.find(x=>x.asset_id==this.item_id && x.collection_id!=default_collection_id)) {
				// 		o.remove = false
				// 	}
				// }
			}

			o.show_original = (this.item_to_show && this.item_to_show != this.item)

			// share with other users: signed in; the item is not archived [MC]; not for banks at this time
			o.share = (this.signed_in && !this.from_archived && !this.is_sparkl_bank)

			// copy the item for your use: if this is an agency-sanctioned collection and it isn't a non-sparkl resource, this makes a copy of the lesson/sparkl in your my content area
			// if this is a my collection (or your sandbox), this shows you the share code with instructions for how to make a copy somewhere else
			// sparkl_banks can't be copied for your use
			o.copy_item_for_my_use = false
			if (this.signed_in && !this.from_archived && !this.is_sparkl_bank) {
				// for non-sparkl resources, allow for this if the user didn't already copy the item to the default collection
				if (this.is_non_sparkl_resource) {
					o.copy_item_for_my_use = !empty(this.my_default_collection.units[2].resources.find(x=>x.resource_id == this.item.resource_id))
				
				// for lessons and sparkls, we allow for it if the user hasn't already made a copy for their use and they're not allowed to edit the item in this context
				} else {
					if (this.item_copy_for_user == null && !o.edit) {
						o.copy_item_for_my_use = true
					}
				}
			}

			// For now at least, if import_to_district is true (which will be the case if this is GA inspire and the user is in Henry County), don't show the copy_item_for_my_use option
			if (o.import_to_district) o.copy_item_for_my_use = false

			// if managing assignments and this is a non-sparkl, non-sparkl_bank, non-teacher_facing resource, offer to create an activity from it
			// TODO: roles -- for many of the things here??
			o.create_sparkl_from_resource = this.signed_in && this.manage_assignments && this.is_non_sparkl_resource && !this.item.teacher_facing && this.collection_type == 'course'
			o.add_to_gc = o.create_sparkl_from_resource		// for now at least use the same thing here...

			// create_sparkl: not currently deployed, but it would be signed in AND the item is a non-sparkl resource
			o.create_sparkl = (false && (this.signed_in && this.is_non_sparkl_resource))

			// report issue/feedback: signed in, site_config.show_submit_feedback_option_for_resources 'yes', agency_sanctioned
			o.report_issue = (this.signed_in && this.site_config.show_submit_feedback_option_for_resources == 'yes' && this.item.agency_sanctioned)

			// show_restricted_resources_control: site_config setting is on and in the unit editor (which implies that you also have edit access).
			// we don't just check o.edit here because for 'my' collections, we *don't* want to show the edit restriction option, or show the "unlocked" icon in the menu, unless you're in the unit editor
			o.show_restricted_resources_control = this.site_config.show_restricted_resources_control == 'yes' && this.in_unit_editor

			// show the "add to open lesson" option if...
			o.add_to_lesson = false
			// this isn't a lesson and we are in the context of a collection
			if (!this.is_lesson && this.lp_context) {
				// and there is a lesson that is open, shifted to the right, and being edited
				if (this.lp_context.shifted_for_lesson && this.lp_context.shifted_for_lesson.lesson_being_edited) {
					o.add_to_lesson = true

					// and if this option is on, *don't* show some of the other options
					o.edit = false
					o.remove = false
					o.show_original = false
				}
			}

			// currently, at least, if we're in a collection folder (not a lesson) and you can edit the item, you can duplicate the item, if it's a lesson or sparkl activity (not a bank at this time)
			// EXCEPTION: If the item has been 'copied for my use', we do not allow duplication unless user is in the 'My Content' view0
			o.duplicate = (o.edit && !this.item_copy_for_user && !this.lesson_context && (this.is_lesson || this.is_sparkl))

			return o
		},
		item_title() {
			let s
			if (this.is_lesson) s = this.item_to_show.lesson_title
			else s = this.item_to_show.description

			if (this.item.restricted) s += '<i class="ml-2 fas fa-lock"></i>'

			if (this.item.stars_available > -1) s += `<nobr class="k-resource-collection-item-stars">${this.item.stars_available}<i class="fas fa-star"></i></nobr>`

			// if the item lives in another unit, show a star to indicate this in the title? no this probably isn't needed
			// if (!empty(this.from_other_unit_string)) s += ' *'
			return s
		},
		item_type() {
			if (this.is_lesson) return 'lesson'
			return this.item.type
		},
		item_id() {
			if (this.is_lesson) return this.item_to_show.lesson_id
			return this.item_to_show.resource_id
		},

		sparkl_activity_owned_by_user() { return this.user_info.user_id != 0 && this.user_info.user_id == this.item_to_show.creator },

		course_unit_header() { 
			// in this version of Cureum, don't show the course name in the header in lessons
			return ''
			return this.item.course_unit_header() 
		},		// only used for lessons
		date_header() { return this.item.date_header() },				// only used for lessons
		card_color_lesson() {
			const { lesson } = this.site_config.asset_type_color
			return lesson || '#2962ff' // $v-blue-accent-4
		},
		card_color_sparkl() {
			const { sparkl_activity } = this.site_config.asset_type_color
			return sparkl_activity || '#651fff' // $v-deep-purple-accent-3
		},
		card_color_sparkl_bank() {
			const { sparkl_activity_bank } = this.site_config.asset_type_color
			return sparkl_activity_bank || '#D50000' // $v-red-accent-4
		},
		card_color_assessment() {
			const { assessment } = this.site_config.asset_type_color
			return assessment || '#c51162' // $v-pink-accent-4
		},
		card_color_leader_resource() {
			const { leader_resource } = this.site_config.asset_type_color
			return leader_resource || '#4e342e' // $v-brown-darken-3
		},
		card_color_teacher_resource() {
			const { teacher_resource } = this.site_config.asset_type_color
			return teacher_resource || '#00695c' // $v-teal-darken-3
		},
		card_color_student_resource() {
			const { student_resource } = this.site_config.asset_type_color
			return student_resource || '#e65100' // $v-orange-darken-4
		},
		color_for_asset_type() {
			switch (true) {
				case this.item_type == 'lesson':
					return this.card_color_lesson
				case this.item_type == 'sparkl':
					return this.card_color_sparkl
				case this.item_type == 'sparkl_bank':
					return this.card_color_sparkl_bank
				case this.item_type == 'assessment':
					return this.card_color_assessment
				case this.item.lp_category == 'leader_resource':
					return this.card_color_leader_resource
				case this.item.teacher_facing:
					return this.card_color_teacher_resource
				default:
					return this.card_color_student_resource
			}
		},
		card_class() {
			let c = ''

			// SF 10/24: This was previously used to set the background color of the cards based on asset type
			// We have moved this determination to site config and the preceding `color_for_asset_type` computed property.
			// However, leaving these class additions here as it is referenced in CollectionResourceFolder
			// if (this.item_type == 'lesson') c += ' k-resource-collection-item--lesson'
			// else if (this.item_type == 'sparkl') c += ' k-resource-collection-item--sparkl-activity'
			// else if (this.item_type == 'sparkl_bank') c += ' k-resource-collection-item--sparkl-bank'
			// else if (this.item_type == 'assessment') c += ' k-resource-collection-item--assessment'
			// else if (this.item.lp_category == 'leader_resource') c += ' k-resource-collection-item--leader-resource'
			// else if (this.item.teacher_facing) c += ' k-resource-collection-item--teacher-resource'
			// // else if (this.item.target_students == 'ese') c += ' k-resource-collection-item--ese'
			// // else if (this.item.target_students == 'adv') c += ' k-resource-collection-item--adv'
			// else c += ' k-resource-collection-item--student-resource'

			if (this.show_all_standards && this.full_width_resource) c += ' k-resource-collection-item-show-all-standards'

			// if (this.search_match == 'yes') c += ' k-resource-collection-item--search_match'
			if (this.$store.state.last_viewed_resource_id == this.item_id) c += ' k-resource-collection-item--last-viewed'

			// add class to reduce font size for long names
			let title_text = U.html_to_text(this.item_title)
			let title_len = title_text.length
			let word_len = title_text.split(/\s+/).sort((a,b)=>b.length-a.length)[0].length
			if (title_len > 45 || word_len > 12) c += ' k-resource-collection-item-really-long-title'
			else if (title_len > 30 || word_len > 9) c += ' k-resource-collection-item-long-title'

			if (this.large) c += ' k-resource-collection-item-large'

			// add a class if the user has made a copy for their use
			if (this.item_copy_for_user) {
				// but don't apply this class if the user is editing the unit (where you always edit the original), or in the default collection (where everything is yours!)
				if (!this.in_unit_editor && !this.viewing_my_default_collection) c += ' k-resource-collection-imported-to-my-collections'
			}

			return c
		},
		icon() {
			if (this.item_type == 'lesson') return 'fa-rectangle-list'
			// if not a lesson, use Resource/Activity.icon()
			return this.item_to_show.icon()
		},
		reslink() {
			// console.warn('reslink', this.item_to_show)
			// link/url that we want to be used from the outside
			return this.item_to_show.standalone_link()
		},
		url() {
			// this function should only be used for resources
			return this.item_to_show.full_url()
			// if (this.item_type == 'upload' || this.item_type == 'html') {
			// 	let url = this.item_to_show.full_url()

			// 	// // TEMP FOR SANDBOX: for imported henry courses where the upload/html resource was created prior to 12/1/2023, have to direct to the henryconnects server
			// 	// if (this.lp_context && this.lp_context.lp_source() == 'henry') {
			// 	// 	// but not if the description starts with a *, or is created after 12/31/2023
			// 	// 	if (this.item_to_show.description[0] != '*' && this.item_to_show.created_at < '2024-01-01') {
			// 	// 		url = `https://henryconnects.henry.k12.ga.us/user-files/${this.item_to_show.url}`
			// 	// 	}
			// 	// }

			// 	return url

			// } else {
			// 	return this.item_to_show.url
			// }
		},
		inline_document_src() {
			if (this.show_inline_document) return this.url
			else return ''
		},
		resource_iframeable() {
			// assume iframeing is OK for html and some uploads
			if (this.item_type == 'html') return true

			// Note: sharepoint docs don't open in an iframe; google docs probably wouldn't either
			// if (this.item_type == 'document') return true

			// youtube videos might work? but not always unfortunately
			// if (this.item.url.search(/youtu.*?be/i) > -1) return true

			// if (['fa-file-pdf', 'fa-file-image'].includes(this.icon)) return true

			// uploaded pdfs and images should work
			if (this.item_type == 'upload') {
				if (this.url.search(/\.pdf/i) > -1) return true
				if (this.icon == 'fa-file-image') return true
			}

			// for other things be safe and don't allow it
			return false
		},
		copyable_title() {
			return $(sr('<div>$1</div>', this.item_title)).text()
		},
		in_last_search_results() {
			// note that we only save one set of collection_last_search_results, for the most recent search in any collection
			return this.$store.state.collection_last_search_results.includes(this.item_id)
		},
		created_date() {
			if (!this.item.created_at) return ''	// tcc items don't have dates

			let d
			if (this.is_lesson) {
				d = new Date(this.item.created_at*1000)
			} else {
				d = date.parse(this.item.created_at, 'YYYY-MM-DD HH:mm:ss')
			}
			return date.format(d, 'MMM D, YYYY')	// Jan 3, 2020 3:04 PM
			// return date.format(d, 'MMM D, YYYY h:mm A')	// Jan 3, 2020 3:04 PM
		},
		resource_is_unshowable_msg() {
			return ''
			// TEMP: for imported henry courses, can't show google drive resources
			if (this.is_lesson || this.is_sparkl) return ''
			let msg = ''
			if (this.lp_context && this.lp_context.lp_source() == 'henry') {
				if (this.url.search(/(drive|docs)\.google/) > -1) {
					// but if the description starts with a '*', allow it through
					if (this.item_to_show.description[0] != '*') {
						msg = 'This is a Google Drive document from Henry County,<br>which is not viewable in this sandbox.'
					}
				}
			}
			return msg
		},
		todo_complete: {
			get() {
				// todo_status value is a timestamp, so if it's > 100, it's complete
				// note that the todo_status for a video may be 5-95, indicating partial completion
				return this.user_info.todo_status[this.item.resource_id] > 100
			},
			set(val) {
				// record new value in database; the dispatch will update the store
				// if complete, we send a value of 100, which will get saved in the db as the timestamp
				let payload = {resource_id: this.item.resource_id, todo_status: (val ? 100 : 0)}
				U.loading_start()
				this.$store.dispatch('save_resource_completion', payload).then(x=>U.loading_stop()).catch(x=>U.loading_stop())
			}
		},
		is_required_video() {
			return this.item.type == 'video' && this.item.todo && U.youtube_id_from_url(this.item.url)
		},
		from_ca_mapping() {
			if (this.viewing_my_default_collection) {
				let default_collection_id = this.my_default_collection && this.my_default_collection.default_collection_id_for_collection_asset_mapping()
				return this.my_ca_mappings.find(x=>x.asset_id==this.item_id && x.collection_id!=default_collection_id)
			}
			return null
		},
		from_collection() {
			if (!this.from_ca_mapping) return null
			return this.$store.state.all_courses.find(x=>x.lp_id==this.from_ca_mapping.collection_id)
		},
		from_collection_string() {
			if (!this.from_ca_mapping) return null
			if (!this.from_collection && !this.$store.state.all_courses_loaded) {
				this.$store.dispatch('get_all_courses', 'initial no_loader')
			}
			// console.log('title: ' + this.from_collection?.title)
			if (this.from_collection?.title) return this.from_collection.title
			else return 'Course ID ' + this.from_ca_mapping.collection_id
		},
		from_other_unit_string() {
			// if the user is an lp admin and this item doesn't actually live in this unit, show them this so that they know where to find the item to edit
			if (!this.lp_context || !this.unit_context) return ''
			if (!this.lp_context?.user_is_lp_admin()) return ''
			
			if (this.is_lesson) {
				if (!this.unit_context.lessons.find(x=>x.lesson_id == this.item_id)) {
					for (let unit of this.lp_context.units) {
						if (unit.lessons.find(x=>x.lesson_id == this.item_id)) {
							// console.warn('here: ', object_copy(unit))
							let s = unit.title
							if (unit.display_number) s = unit.display_number + ': ' + s
							return `*Lives in “${s}”`
						}
					}
					return '*This lesson does not “live” in this unit.'
				}

			} else {
				if (!this.unit_context.resources.find(x=>x.resource_id == this.item_id)) {
					for (let unit of this.lp_context.units) {
						if (unit.resources.find(x=>x.resource_id == this.item_id)) {
							// console.warn('here: ', object_copy(unit))
							let s = unit.title
							if (unit.display_number) s = unit.display_number + ': ' + s
							return `*Lives in “${s}”`
						}
					}
					return '*This resource does not “live” in this unit.'
				}
			}
			return ''
		},

		resource_extensions() {
			let html = ''
			// some sites, e.g. ALEX, include "extensions" in their resources
			let ext = this.item_to_show.extensions
			if (ext) {
				if (ext.content) {
					if (ext.content.content_source) html += `<li><b class="grey--text text--darken-3">Content Source:</b> ${ext.content.content_source}</li>`
					if (ext.content.content_type) html += `<li><b class="grey--text text--darken-3">Content Type:</b> ${ext.content.content_type}</li>`
				}

				if (ext.license) {
					let s = ''
					if (ext.license.license_type.toLowerCase() != 'custom') s = ext.license.license_type
					else if (ext.license.license_uri) s = ext.license.license_uri
					else s = 'Custom'
					if (ext.license.license_uri) s = `<a href="${ext.license.license_uri}" target="_blank">${s}</a>`
					html += `<li><b class="grey--text text--darken-3">License:</b> ${s}</li>`
				}

				if (ext.approved_date) html += `<li><b class="grey--text text--darken-3">Approved date:</b> ${ext.approved_date}`

				if (html) html = `<ul>${html}</ul>`
			}
			return html
		},
	},
	watch: {
		// not currently using the tcc checkboxes on individual resources; only folders
		// checkbox_initially_checked: { immediate:true, handler() { this.checkbox_checked = this.checkbox_initially_checked }},
	},
	created() {
	},
	mounted() {
		// somewhat hackish way to determine if we are currently in the unit editor
		setTimeout(x=>{
			if ($(vapp.collection_unit_editor?.$el).is(':visible')) {
				this.in_unit_editor = true
			}
			if ($(this.$el).parents('.k-lesson-edit').length > 0) {
				this.in_lesson_editor = true
			}
			// console.log(`this.in_unit_editor = ${this.in_unit_editor} -- ${this.item_noun()} ${this.item_title}`)
		}, 50)
		if (this.item.is_new_resource == true) {
			this.edit_item_start()
		}
	},
	methods: {
		icon_color(hover) {
			// if we're in list view, icon gets the color_for_asset_type
			if (this.$store.state.lst.collection_view_mode == 'list' || this.collection_type === 'pd') {
				return this.color_for_asset_type
			}
			// for a "tile", we use white, with a change in opacity while hovered
			if (hover) return 'rgba(255,255,255,0)'
			else return 'rgba(255,255,255,0.8)'
		},

		item_noun(capitalize) {
			return this.item.type_label(capitalize)
			// if (this.is_lesson) return capitalize ? 'Lesson' : 'lesson'
			// if (this.is_sparkl) return capitalize ? 'Activity' : 'activity'
			// if (this.is_sparkl_bank) return `${this.site_config.sparkl_app_name} ${capitalize ? 'Item Bank' : 'Item Bank'}`
			// return capitalize ? 'Resource' : 'resource'
		},

		show_original() {
			this.force_show_original = true
			this.card_clicked()
		},

		card_clicked(flag) {
			let log_resource_id = this.item_to_show.resource_id
			let log_resource_title = this.item_title
			let log_action = 'view'

			// TEMP FOR SANDBOX: if non-showable, inform the user
			let msg = this.resource_is_unshowable_msg
			if (msg) {
				this.$inform({text:msg, color:'pink darken-3', snackbarTimeout:8000})
				return
			}

			// if a lesson, show it
			if (this.item_type == 'lesson') {
				// cancel previous lesson shift if necessary (the previous lesson shift would be in a different ResourceCollectionItem)
				this.$emit('lesson_shift_update', {cancel_last: true})

				this.lesson_showing = true

				// log values are different for lessons
				log_resource_id = this.item_to_show.lesson_id
				log_resource_title = this.item_to_show.lesson_title
				log_action = 'view_lesson'

			// if this is quick-lookable, or we're using quick_look for all resources, show in dialog
			} else if (this.resource_iframeable || this.site_config.quick_look_for_all_resources == 'yes') {
				this.show_quick_look()

			// if type is 'collection_item', or type is 'lti', call get_resource_record to get the full resource data and retrieve the lti_form if necessary
			} else if (this.item_type == 'collection_item' || this.item_type == 'lti') {
				// (note that lti forms are timestamped, so we need to get a new form every time the link is launched)
				// PW: I don't think we meant to not log these...
				// log_resource_usage = false
				this.get_resource_record(true)

			} else if (this.item_type == 'sparkl') {
				log_resource_id = this.item_to_show.url
				log_action = 'view_sparkl'
				// if the user is allowed to edit the activity, show in edit mode (for sparkl activities we don't distinguish between edit and view mode)
				let embed_mode = this.option_availability.edit ? 'edit' : 'view'
				console.log(embed_mode, this.option_availability)
				this.show_sparkl(embed_mode)

			} else if (this.item_type == 'sparkl_bank') {
				log_resource_id = this.item_to_show.url
				log_action = 'view_sparkl_bank'
				// if the user is allowed to edit the bank, show in edit mode (for sparkl activities we don't distinguish between edit and view mode)
				let embed_mode = this.option_availability.edit ? 'edit' : 'view'
				console.log(embed_mode, this.option_availability)
				this.show_sparkl(embed_mode)

			} else if (this.is_required_video) {
				this.show_video_player = true

			} else if (this.item_type == 'assessment') {
				// for assessments, we set show_assessment_options to true to show links + instructions
				this.show_assessment_options = true

			} else {
				// else open the url in a new window
				window.open(this.url)
			}

			// log signed or unsigned in users viewing resources
			let uemail = (this.signed_in) ? this.user_info.email : 'unsignedin@cglt.com'
			let uid    = (this.signed_in) ? this.user_info.user_id : 3859
			let school = (this.signed_in && this.user_info.district_department) ? this.user_info.district_department.join(',') : ''

			this.link_clicked = true

			this.$store.dispatch('log_resource_usage', {
				'resource_id': log_resource_id,
				'resource_title': log_resource_title,
				'action': log_action,
				'user_id': uid,
				'user_email': uemail,
				'system_role': this.user_info.system_role,
				'school': school,
				// course_code is added by the dispatch fn
			})

			// mark as the last-viewed resource
			this.mark_as_last_viewed_resource()
		},

		mark_as_last_viewed_resource() {
			this.$store.commit('set', ['last_viewed_resource_id', this.item_id])
		},

		get_resource_record(and_launch) {
			this.$store.commit('set', [this.item_to_show, 'full_resource_data_loaded', true])

			let payload = {resource_id: this.item_to_show.resource_id}
			if (and_launch == true) payload.get_lti_form = 'yes'

			this.$store.dispatch('get_resource_record', payload).then(result=>{
				// fill in parts of the resource that we need but might not have been fully filled in previously, including overwriting the type
				this.$store.commit('set', [this.item_to_show, 'type', result.resource_record.type])
				this.$store.commit('set', [this.item_to_show, 'url', result.resource_record.url])
				this.$store.commit('set', [this.item_to_show, 'long_description', result.resource_record.long_description])

				// if and_launch is true, launch the link once we get the record data back
				if (and_launch == true) {
					// if we got back an lti_form, launch
					if (!empty(result.lti_form)) {
						// for the lti_form we open a new window and write out the form, which submits itself
						// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
						let w = window.open()
						w.document.write(result.lti_form)
					} else {
						// else we just open the new window to the resource's url
						window.open(this.url, '_blank')
					}
				}
			})
		},

		add_to_gc() {
			let logging_resource_id = this.item_to_show.resource_id
			let logging_action = 'add_to_gc'
			let logging_title = this.item_to_show.description
			if (this.item_type == 'lesson') {
				logging_resource_id = this.item_to_show.lesson_id // other types to handle?
				logging_action = 'add_to_gc_lesson'
				logging_title = this.item_to_show.lesson_title
			}

			let school = (this.user_info.district_department) ? this.user_info.district_department.join(',') : ''

			this.$store.dispatch('log_resource_usage', {
				'resource_id': logging_resource_id,
				'resource_title': logging_title,
				'action': logging_action,
				'user_id': this.user_info.user_id,
				'user_email': this.user_info.email,
				'system_role': this.user_info.system_role,
				'school': school,
				// course_code is added by the dispatch fn
			})
			
			let url = sr('https://classroom.google.com/share?url=$1', this.reslink)
			// add title to url
			url += sr('&title=$1', this.copyable_title)
			window.open(url)

			this.mark_as_last_viewed_resource()
		},

		add_to_lms() {
			U.copy_to_clipboard(this.reslink)
			this.$inform('LTI link copied to clipboard: ' + this.reslink)
		},

		copy_link_to_clipboard() {
			U.copy_to_clipboard(this.reslink)
			this.$inform({text: '<b>' + this.item_noun(true) + ' link copied to clipboard:</b><br>' + this.reslink, color:'green darken-3'})

			this.mark_as_last_viewed_resource()
		},

		share_item(flag) {
			let shared_item_id
			if (this.is_lesson) shared_item_id = 'L' + this.item_to_show.lesson_id
			// note that for sparkl activities we use the sparkl activity_id, which is in the url field; copy_assets_for_adding_to_collection deals with this
			else if (this.is_sparkl || this.is_sparkl_bank) shared_item_id = 'A' + this.item_to_show.url
			else shared_item_id = 'R-' + this.item_to_show.resource_id

			vapp.share_item(this.item_noun(true), shared_item_id, flag)
		},

		print_resource() {
			if (this.show_inline_document) {
				let frm = document.getElementById('inline_iframe_' + this.item_to_show.resource_id).contentWindow
				frm.focus()	// focus on contentWindow is needed on some ie versions
				frm.print()
			} else {
				window['inline_iframe_' + this.item_to_show.resource_id].print()
			}
		},

		open_resource_in_new_window() {
			if (!this.show_inline_document) {
				window.open(this.reslink)
			} else {
				let w = window.open()
				this.write_inline_html(w)
			}
		},

		// allow the user to shift the lesson to the right of the screen, so that other resources in the lesson (e.g. unit planning guides) are viewable on the left
		// note that the btn to call this fn only shows if we are showing the lesson in the context of a collection
		shift_lesson(val) {
			if (typeof(val) == 'boolean') this.lesson_shifted = val
			else this.lesson_shifted = !this.lesson_shifted

			// emit an event that will bubble up to the collection, that causes the collection's resources to be shifted to the left; and makes it so that if the user switches to a different unit or collection, the lesson will be closed and the collection will be unshifted.
			if (this.lesson_shifted) this.$emit('lesson_shift_update', {shifted_for_lesson:this})
			else this.$emit('lesson_shift_update', {shifted_for_lesson:false})
		},

		print_lesson() {
			this.show_print_dialog = true

			this.mark_as_last_viewed_resource()
		},

		close_lesson() {
			// make sure the lesson isn't shifted -- and that the collection also isn't shifted
			this.shift_lesson(false)
			this.lesson_showing = false
			this.force_show_original = false
		},

		show_quick_look() {
			// for html, we have to load it first...
			if (this.item_type == 'html') {
				this.show_inline_html = this.resource_quick_look_showing = true
				// get html fresh every time, in case of an edit
				$.ajax({
					url: this.url,
					success: (html)=>{
						if (html.search(/^([\s\S]*?)<hr>([\s\S]*)/) > -1) {
							this.inline_html_always = RegExp.$1
							this.inline_html_extra = RegExp.$2
						} else {
							this.inline_html_always = html
							this.inline_html_extra = ''
						}

						// call fn to write the inline html to the iframe that will be showing on nextTick
						this.$nextTick(x=>this.write_inline_html())
					},
					cache: false,
				})
				return
			} 
			
			if (this.resource_iframeable) {
				this.show_inline_document = true
			}

			this.resource_quick_look_showing = true

			// note that if a lesson is shifted to the right and something in a quick look is then shown, the quick look will be shifted to the left via the content-class attribute on the quick look dialog
		},

		hide_quick_look() {
			this.show_inline_document = this.show_inline_html = this.resource_quick_look_showing = false
		},

		write_inline_html(w) {
			// we write inline html to an iframe so that it's printable and openable in a new window

			let writing_in_iframe = (!w || !w.document)

			if (writing_in_iframe) {
				w = window['inline_iframe_' + this.item_to_show.resource_id]
			}
			w.document.open()
			w.document.write('<html><head>')
			w.document.write(sr('<title>$1</title>', this.item_to_show.description))
			w.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/froala-editor/4.0.10/css/froala_style.min.css" crossorigin="anonymous">')
			w.document.write('<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,900;1,400;1,900&display=swap" rel="stylesheet">')
			// MathLive styles; see also U.inject_mathlive_styles
			w.document.write(`<style>${U.mathlive_core_css}</style>`)


			w.document.write(`
				<style>
				body {
					font-family:Roboto, sans-serif;
					font-size:16px;
					line-height:1.4em;
					color:#000;
				}
				.no-print { display:none; }
				@media print {
					.no-print { display:block; }
				}
				</style>
				<script>
				window.toggle_more = function() {
					var el = document.getElementById('html_extra');
					if (el.getAttribute('data-showing') == 'yes') {
						el.setAttribute('data-showing', 'no');
						el.setAttribute('style', 'display:none');
					} else {
						el.setAttribute('data-showing', 'yes');
						el.setAttribute('style', 'display:block');
					}
				}
			`)
			w.document.write('</' + 'script>')

			w.document.write('</head><body>')

			w.document.write('<div class="fr-view" style="max-width:760px; margin-left:auto; margin-right:auto;">')
			w.document.write(sr('<h3 class="$1">$2</h3>', (writing_in_iframe?'no-print':''), this.item_to_show.description))
			w.document.write(sr('<div>$1</div>', U.render_latex(this.inline_html_always)))
			if (this.inline_html_extra) {
				w.document.write('<div style="border-top:1px solid #ccc;">')
				w.document.write('<div style="text-align:center; margin-top:12px; margin-bottom:8px"><button onClick="window.toggle_more()">Toggle More</button></div>')
				w.document.write(sr('<div id="html_extra" style="display:none">$1</div>', U.render_latex(this.inline_html_extra)))
				w.document.write('</div>')
			}
			w.document.write('</div>')

			w.document.write('</body></html>')
			w.document.close()
		},

		edit_item_restriction() {
			if (!this.option_availability.show_restricted_resources_control) return
			// let msg = `Items restricted to authorized teachers and staff will only be shown to users who have signed in to ${this.site_config.app_name} and who have a designated role of Teacher or Staff.<br><br>`
			let msg = `Items restricted to authorized teachers and staff will only be shown to users who have signed in to ${this.site_config.app_name}.<br><br>`

			let acceptText, cancelText
			if (this.item_to_show.restricted) {
				msg += `Access to this item is currently <b class="red--text text--darken-3">restricted</b>. Click the right button below to continue restricting the item, or click the left button to open the item to any users.`
				acceptText = 'Continue Restricting Item'
				cancelText = 'Do Not Restrict Item'

			} else {
				msg += `Access to this item is currently <b class="green--text text--darken-3">not restricted</b>. Click the left button below to leave the item unrestricted, or click the right button to restrict the item to authorized teachers and staff.`
				acceptText = 'Restrict Item'
				cancelText = 'Leave Item Unrestricted'
			}

			this.$confirm({
				// title: 'Are you a witch?',
				text: msg,
				acceptText: acceptText,
				acceptColor: 'red darken-3',
				acceptIcon: 'fas fa-lock',
				cancelText: cancelText,
				cancelColor: 'green darken-3',
				cancelIcon: 'fas fa-lock-open',
				dialogMaxWidth: 600,
			}).then(y => {
				// accept (right btn) == set restricted to 'teacher'
				this.edit_item_restriction_save('teacher')
			}).catch(n=>{
				// cancel (left btn) == clear restricted flag
				this.edit_item_restriction_save('')
			}).finally(f=>{})
		},

		edit_item_restriction_save(val) {
			// this will definitionally only be called when you are allowed to edit the resource
			this.$store.commit('set', [this.item_to_show, 'restricted', val])

			if (this.item_type == 'lesson') {
				// for save_lesson we only need to send in the lesson data we want to change, here just the restricted value
				U.ajax('save_lesson', {
					user_id: this.user_info.user_id,
					lesson_class: 'teacher',	// this won't actually matter
					lesson_data: JSON.stringify({lesson_id: this.item_to_show.lesson_id, restricted: val})
				}, result=>{
					if (result.status != 'ok') {
						console.log('Error in ajax call: ' + result.status); vapp.ping(); return;
					}

					// updated data will be returned; emit it to the parent component
					this.$emit('edit_item_saved', {type:'lesson', updated_lesson: result.updated_lesson, edited_lesson:this.item_to_show})	// pass edited_lesson so we can preserve *_showing values
				})

			} else {
				this.$store.dispatch('save_resource', { resource: new Resource(this.item_to_show) }).then(saved_resource_data => {
					U.loading_stop()

					let r = new Resource(saved_resource_data)
					// emit edit_item_saved to update the activity in whatever collection we're editing
					this.$emit('edit_item_saved', {type:'resource', updated_resource: r})
				}).catch(error=>{
					console.log(error)
					this.$alert(sr('A problem occurred when attempting to save the resource. Message from server:<div class="mt-2">$1</div>', error))
				})
			}
		},

		edit_item_start() {
			// this is called for a lesson, resource, or sparkl activity; open the appropriate editor
			// note that sparkl activities are edited from the sparkl window
			if (this.is_lesson) {
				if (this.lesson_being_edited) {
					this.$alert('You must close the editor for the lesson you’re currently editing before you start editing a different lesson.')
					return
				}
				// set lesson_being_edited_lesson_class; by default it's 'teacher'
				this.lesson_being_edited_lesson_class = 'teacher'
				// if this is a new lesson, look for a is_lesson_template flag
				if (this.item_to_show.lesson_id == 0) {
					if (this.item_to_show.is_lesson_template) this.lesson_being_edited_lesson_class = 'template'
				// else if we're editing an existing template lesson, class is 'template'
				} else if (this.item_to_show.lesson_id == this.item_to_show.lesson_template_id) {
					this.lesson_being_edited_lesson_class = 'template'
				}

				// get the lesson, even if we already loaded it, to make sure it hasn't been edited by someone else since it was last loaded
				const payload = {
					user_id: this.user_info.user_id,
					lesson_id: this.item_to_show.lesson_id
				}
				this.$store.dispatch('load_lesson', this.item_to_show).then(()=>{
					// then check the lesson out for editing; if the service returns an error, the store will alert the user and reject
					this.$store.dispatch('check_out_lesson_for_editing', payload).then(() => {
						vapp.rci_being_edited = this	// debugger
						this.lesson_being_edited = this.item_to_show
					}).catch((err) => {
						console.log(err)
					})
				})
			
			} else if (this.is_sparkl || this.is_sparkl_bank) {
				// note that currently (6/21/2023), we are only explicitly asked to edit when a new activity is added from DirectivesWrapper
				// PW: ???
				this.show_sparkl('edit')
				
			} else {
				this.resource_being_edited = this.item_to_show
			}
			this.mark_as_last_viewed_resource()
		},

		edit_resource_cancel() {
			// bubble up event in case a higher component needs to do something (e.g. remove a newly-added resource)
			this.$emit('edit_item_cancel', {type: 'resource', edited_resource: this.item_to_show})
			this.resource_being_edited = null
		},

		edit_resource_saved(updated_resource) {
			// the resource editor will have saved the resource to the db; bubble updated_resource up to the component that needs to handle it
			this.$emit('edit_item_saved', {type: 'resource', updated_resource: updated_resource})
			// note that we don't call edit_resource_cancel because we don't want *that* event to bubble up
			this.resource_being_edited = null
		},

		edit_lesson_cancel() {
			// bubble up event in case a higher component needs to do something (e.g. remove a newly-added resource)
			this.$emit('edit_item_cancel', {type: 'lesson', edited_lesson: this.item_to_show})
			U.ajax('reset_checked_out_for_edit', {user_id: this.user_info.user_id, lesson_id: this.lesson_being_edited.lesson_id})
			this.lesson_being_edited = null
			this.convert_to_llm = false
		},

		edit_lesson_saved(args) {
			// TODO: update lesson...
			// if we were editing an existing lesson, update it here
			// we have to take care of this here because you might be, for example, viewing your copy of the lesson from the course resources area, in which case CourseResources.vue won't know to update your copy
			// if (this.item_to_show.lesson_id != 0 && this.item_to_show.lesson_id == args.updated_lesson.lesson_id) {
			// 	let index = this.my_lessons.findIndex(x=>x.lesson_id == args.updated_lesson.lesson_id)
			// 	if (index > -1) this.my_lessons.splice(index, 1, new Lesson(args.updated_lesson))
			// }

			// the lesson editor will have saved the resource to the db; bubble updated_resource up for further processing
			this.$emit('edit_item_saved', {type: 'lesson', updated_lesson: args.updated_lesson, edited_lesson: args.edited_lesson, flag: args.flag})

			// if the flag is 'and_close', close the editor
			if (args.flag == 'and_close') {
				this.lesson_being_edited = null
				this.convert_to_llm = false
				// and check the lesson back in so someone else can edit it if necessary
				U.ajax('reset_checked_out_for_edit', {user_id: this.user_info.user_id, lesson_id: args.edited_lesson.lesson_id})
			} else {
				// after a tick, update lesson_being_edited, becase the save will have changed the item_to_show object. this change will be sent through to the lesson editor
				this.$nextTick(x=>this.lesson_being_edited = this.item_to_show)
			}

			// also, if this was a new lesson we also have to close the editor, so that the editor can be re-opened with the "full version" of the lesson
			// NO LONGER NEEDED??
			// if (this.item_to_show.lesson_id == 0) this.lesson_being_edited = null
			// note that we don't call edit_resource_cancel because we don't want *that* event to bubble up
		},

		duplicate_item(flag) {
			// we have to check the lp out for editing before being able to duplicate, unless we're in my_content or the sandbox or the unit editor
			if (!(this.viewing_my_default_collection || this.viewing_my_shadow_unit || this.in_unit_editor)) {
				// PW: I don't think this should happen...
				if (!this.lp_context) {
					this.$alert('You cannot duplicate this item from here (38492).')
					return
				}
				if (flag != 'checked_out') {
					this.$store.dispatch('edit_access_control', {lp_id: this.lp_context.lp_id, lp_updated_at: this.lp_context.updated_at, action: 'checkout'}).then((result)=>{
						console.log('edit request (duplicate_item): ' + result.status)
						this.duplicate_item('checked_out')
					}).catch((e)=>{
						// if this doesn't work, don't enter edit mode
						console.log('error requesting editor checkout for duplicate_item', e)
					})
					return
				}
			}

			// note that a version of duplicate_item also exists in DirectivesWrapper
			console.log('duplicate_item!', this.item)
			let noun, title, item_id_prefix, item_id
			if (this.is_lesson) {
				noun = 'Lesson'
				title = this.item.lesson_title
				item_id_prefix = 'L'
				item_id = this.item.lesson_id
			} else {
				noun = 'Activity'
				title = this.item.description
				item_id_prefix = 'A'
				item_id = this.item.url
			}
			this.$prompt({
				title: 'Duplicate ' + noun,
				text: `Enter a title for the duplicated ${noun}:`,
				initialValue: title,
				disableForEmptyValue: true,
				acceptText: 'Duplicate',
				acceptIcon: 'fas fa-copy',
			}).then(new_title => {
				new_title = $.trim(new_title)

				// follow the pattern from DirectivesWrapper / ResourceSearch - CollectionResourceFolder - CollectionUnit...
				// start by using copy_assets_for_adding_to_collection to copy the item
				let payload = {
					user_id: this.user_info.user_id, 
					resource_ids: [], 
					lesson_ids: [],
					agency_sanctioned: 'no',
					new_title: new_title,
				}

				// if we're using a sparkl_origin_override for this collection, send it in
				if (this.lp_context && !empty(this.lp_context.sparkl_origin_override)) {
					payload.sparkl_origin_override = this.lp_context.sparkl_origin_override
				}

				if (item_id_prefix == 'L') payload.lesson_ids.push(item_id)
				else payload.resource_ids.push(item_id)

				U.loading_start()
				U.ajax('copy_assets_for_adding_to_collection', payload, result=>{
					U.loading_stop()

					if (result.status != 'ok') {
						// this shouldn't happen here
						if (result.status != 'bad_item_id') result.status = `An error occurred: invalid item ID (${item_id_prefix}${item_id}).`
						this.$alert(result.status)
						return
					}

					// pass the copied item up through CollectionResourceFolder via add_duplicated_items (CollectionResourceFolder will add to the unit and save the unit)
					let args = {original_item: this.item, duplicated_items: []}
					if (item_id_prefix == 'L') {
						args.duplicated_items.push(new Lesson(result.copies[0].lesson_data))

					} else {
						args.duplicated_items.push(new Resource(result.copies[0].resource_data))
					}

					// add the similar to how we do it with search -- the handler of add_duplicated_items will add the item to whatever folder we're in and whatever collection we're saving
					this.$emit('add_duplicated_items', args)
				})

			}).catch(n=>{
				// if we checked out, need to check back in
				if (flag == 'checked_out') this.$store.dispatch('edit_access_control_checkin', {lp_id: this.lp_context.lp_id})
			}).finally(f=>{})
		},

		show_sparkl(embed_mode, force_reload) {
			// embed_mode can be 'edit' or 'view'. if we receive a value, use it
			if (typeof(embed_mode) != 'string') {
				// else if the user has the ability to edit here, use 'edit'
				if (this.option_availability.edit) embed_mode = 'edit'
				// else use 'view'
				else embed_mode = 'view'
				// (note that the embed mode doesn't do much more than affect what options are shown in the TeacherStart component)
			}

			// override sparkl_origin if the collection tells us to do so (used for AP Sparkl activities, e.g.)
			let sparkl_origin_override = null
			if (this.lp_context && !empty(this.lp_context.sparkl_origin_override)) {
				sparkl_origin_override = this.lp_context.sparkl_origin_override
			}

			vapp.$refs.sparkl_embed.show_activity({
				resource_type: this.item_type,
				activity_record: {
					tool_activity_id: this.item_to_show.url,
					lti_resource_link_id: this.item_to_show.resource_id,
					activity_title: this.item_to_show.description,
					creator_user_id: this.item_to_show.creator,
				},
				force_reload: force_reload,
				embed_mode: embed_mode,

				// if the user is allowed to edit the activity, launch with a flag that allows the original activity to be edited
				force_allow_original_to_be_edited: this.option_availability.edit ? 'yes' : 'no',

				// and if the user isn't *allowed* to edit in this context, launch with a flag that prevents the original activity from being edited (instead they will be able to copy for their use if they wish)
				force_prevent_original_to_be_edited: (!this.option_availability.edit) ? 'yes' : 'no',

				// if this isn't a bank resource and the user doesn't own the activity and hasn't already copied it for their use, show the button offering them to let them copy it
				show_copy_for_my_use_btn: this.item_type != 'sparkl_bank' && this.option_availability.copy_item_for_my_use,
				viewing_original_of_in_my_collections: this.force_show_original,
				controller_component: this,
				sparkl_origin_override: sparkl_origin_override,
			})

			// hide active dialogs/overlays while the activity shows (e.g. the unit editor might be showing)
			$('.v-dialog__content--active, .v-overlay--active').hide()
		},

		sparkl_activity_saved(activity_data_from_sparkl) {
			console.log(`sparkl_activity_saved in ResourceCollectionItem (option_availability.edit:${this.option_availability.edit}; owned by user:${this.sparkl_activity_owned_by_user}; force_show_original:${this.force_show_original})`, activity_data_from_sparkl)
			// this is called when Sparkl issues a 'sparkl_activity_saved' message
			// activity_data_from_sparkl should include sparkl_activity_id, stars_available, activity_instructions, activity_title, activity_editors, open_to_exercise_bank

			// // if item_to_show (the item in Cureum) isn't owned by the user and we aren't in the unit editor, return: since the activity isn't owned by this user in Cureum, we don't want to save anything
			// if (this.sparkl_activity_owned_by_user == false && !this.in_unit_editor) {
			// correction: if the user isn't allowed to edit the activity, or if this.force_show_original, return; if this is a shared my collection and the user is an editor for the collection, they *are* able to edit the activity
			if (!this.option_availability.edit || this.force_show_original) {
				console.log('ResourceCollectionItem: sparkl_activity_saved called from sparkl iframe, but the user is not allowed to edit the activity, so returning')
				return
			}

			// NOTE: Code below needs to be kept in synch with ResourceSearch (it's not identical, but some things are the same)
			let changed = false
			let resource_copy = this.item_to_show.copy_for_save()

			// if sparkl_activity_id is changed, set it
			if (activity_data_from_sparkl.sparkl_activity_id && activity_data_from_sparkl.sparkl_activity_id != resource_copy.url) {
				// PW 12/2023: I don't think this should ever happen anymore here, because we deal with new activities in ResourceSearch now
				console.log(`Sparkl activity id changed from ${resource_copy.url} to ${activity_data_from_sparkl.sparkl_activity_id}`)
				resource_copy.url = activity_data_from_sparkl.sparkl_activity_id
				changed = true
			}
			
			// ditto the activity title
			if (activity_data_from_sparkl.activity_title && activity_data_from_sparkl.activity_title != resource_copy.description) {
				resource_copy.description = activity_data_from_sparkl.activity_title
				changed = true
			}

			// ditto case_alignments
			if (activity_data_from_sparkl.case_alignments && !this.item_to_show.standards_match(activity_data_from_sparkl.case_alignments)) {
				resource_copy.standards = activity_data_from_sparkl.case_alignments
				changed = true
			}

			// build activity description from the rest of the activity_data_from_sparkl; if changed, set it in resource.long_description
			// (we're not currently using this description, but we might in the future)
			if (activity_data_from_sparkl.stars_available) {
				let d = `Student activity with ${activity_data_from_sparkl.stars_available} star${activity_data_from_sparkl.stars_available == 1 ? '' : 's'} available to be earned`
				
				if (resource_copy.long_description != d) {
					resource_copy.long_description = d
					changed = true
				}
			}

			// if open_to_exercise_bank is 'bravo', that means this is sparkl_bank resource; otherwise it's a "normal" sparkl activity
			let resource_type = (activity_data_from_sparkl.open_to_exercise_bank == 'bravo') ? 'sparkl_bank' : 'sparkl'
			if (resource_type != resource_copy.type) {
				console.warn('setting resource type to: ' + resource_type)
				resource_copy.type = resource_type
				changed = true
			}

			// if we changed anything, save the activity resource to the db (if it was a new activity, the sparkl_activity_id/url will have changed from 0 to the newly-created sparkl activity_id, so changed will have been set to true above)
			if (changed) {
				console.log('saving sparkl activity (ResourceCollectionItem)...')
				U.loading_start()
				this.$store.dispatch('save_resource', { resource: new Resource(resource_copy) }).then(saved_resource_data => {
					U.loading_stop()

					let r = new Resource(saved_resource_data)
					// emit edit_item_saved to update the activity in whatever collection we're editing
					this.$emit('edit_item_saved', {type:'resource', updated_resource: r})

					// once activity is saved, if sparkl_closed_from_embed is true, finish closing sparkl
					if (this.sparkl_closed_from_embed) this.$nextTick(()=>this.close_sparkl_finish())
				})
				// return here so we don't call close_sparkl_finish too quickly below 
				return
			}

			// if we didn't have to save, and sparkl_closed_from_embed is true, finish closing sparkl here
			if (this.sparkl_closed_from_embed) this.close_sparkl_finish()
		},

		close_sparkl() {
			console.log(`close_sparkl in ResourceCollectionItem (option_availability.edit: ${this.option_availability.edit}; owned by user: ${this.sparkl_activity_owned_by_user})`)
			// // if the user is the owner of the activity, and we're not viewing the original item...
			// if (this.sparkl_activity_owned_by_user && !this.force_show_original) {
			// Correction: if the user is allowed to edit, and we're not being forced to show the original item...
			if (this.option_availability.edit && !this.force_show_original) {
				U.loading_start()
				// send the host_activity_saved message TO sparkl, so that Sparkl saves anything that might have been edited there
				console.warn('host_activity_saved being called')
				vapp.$refs.sparkl_embed.execute('host_activity_saved', {})

				this.sparkl_closed_from_embed = true
				// ... then once sparkl is done saving, sparkl_activity_saved will be called, and since sparkl_closed_from_embed is true, sparkl_activity_saved will call close_sparkl_finish
			} else {
				this.close_sparkl_finish()
			}
		},

		close_sparkl_finish() {
			U.loading_stop()
			this.sparkl_closed_from_embed = false
			this.force_show_original = false
			vapp.$refs.sparkl_embed.hide_activity()

			// re-show active dialogs/overlays
			$('.v-dialog__content--active, .v-overlay--active').show()
		},

		// this is called from a sparkl_bank resource when the user creates a new activity from the bank
		activity_created_from_bank(data) {
			console.log('activity_created_from_bank', data)
			// create a resource from the data, which should include: sparkl_activity_id, activity_instructions, activity_title, activity_editors, case_alignments
			let r = new Resource({
				resource_id: 'new',
				type: 'sparkl',
				url: data.sparkl_activity_id,
				description: data.activity_title,
				long_description: `Student activity with ${data.stars_available} star${data.stars_available == 1 ? '' : 's'} available to be earned`,
				stars_available: data.stars_available,
				standards: data.case_alignments,
				creator: this.user_info.user_id,
			})

			// save the resource
			this.$store.dispatch('save_resource', {resource: r}).then(saved_resource_data=>{
				// insert the saved resource in the sparkl unit (1) of the user's sandbox
				let saved_resource = new Resource(saved_resource_data)
				this.$store.commit('set', [this.my_default_collection.units[1].resources, 'PUSH', saved_resource])

				// then dispatch save_default_collection_resources; note that it will add the resource to my_resources and take care of my_ca_mappings
				this.$store.dispatch('save_default_collection_resources').then(x=>{
					console.log('save_default_collection_resources completed!')
				})
			})

		},

		// this is ONLY called when you're in an existing collection (NOT your default collection) and you say you want to create a copy of an item for your use
		// so we're always adding FROM the "base" collection unit TO your shadow unit here
		copy_to_my_content(event) {
			// if we're in a my collection or the sandbox, we can't copy to the shadow unit, so just show the share code
			if (!this.option_availability.copy_item_for_my_use || this.viewing_my_default_collection || this.collection_type == 'my') {
				this.share_item('copy')
				return
			}

			// if user is from an "import_to_district" district, call share_item instead
			// CAVEAT: if shift key is held down, do the normal copy_to_my_content 
			if (this.option_availability.import_to_district && !event?.shiftKey) {
				this.share_item('district')
				return
			}

			if (this.is_non_sparkl_resource) {
				// can this be done???
				this.$emit('copy_to_shadow_unit_finish', this.item)	// in CollectionUnit.vue
			} else if (this.is_sparkl) {
				let title = 'Copy Activity for Your Use?'
				let msg = 'Would you like to make a copy of this activity for your use? This will allow you to edit the activity (if you wish) and use the activity with your students.'
				let acceptText = 'Copy For Your Use'
				let acceptIcon = 'fas fa-copy'
				this.$confirm({
					title: title,
					text: msg,
					acceptText: acceptText,
					acceptIcon: acceptIcon,
					dialogMaxWidth: 600,
				}).then(y => {
					this.copy_to_shadow_unit_finish()
				}).catch(n=>{console.log(n)}).finally(f=>{})
			
			// if we're here it's a lesson
			} else {
				this.copy_to_shadow_unit_finish()
			}
		},

		copy_to_shadow_unit_finish() {
			// for lessons and sparkls, call copy_assets_for_adding_to_collection service for the original item; the service (also used in ResourceSearch) will take care of copying underlying sparkl activities
			let payload = {
				user_id: this.user_info.user_id, 
				agency_sanctioned: 'no',	// since the copy is being created for the user, it isn't agency_sanctioned
			}

			if (this.is_lesson) payload.lesson_ids = [this.item.lesson_id]
			else payload.resource_ids = [this.item.resource_id]

			// if we're using a sparkl_origin_override for this collection, send it in
			if (this.lp_context && !empty(this.lp_context.sparkl_origin_override)) {
				payload.sparkl_origin_override = this.lp_context.sparkl_origin_override
			}

			U.loading_start()
			U.ajax('copy_assets_for_adding_to_collection', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') { this.$alert(result.status); return; }	// shouldn't happen

				let copied_item
				if (this.is_lesson) copied_item = new Lesson(result.copies[0].lesson_data)
				else copied_item = new Resource(result.copies[0].resource_data)

				this.$emit('copy_to_shadow_unit_finish', copied_item)	// in CollectionUnit.vue

				// and for a sparkl activitity, reload the iframe so we see the new activity...
				if (this.is_sparkl) this.show_sparkl('edit', true)

				// if manage_assignments, navigate to Assignment Center? -> if so this.$store.commit('lst_set', ['unit_mode', 'assignments'])
			})
		},

		report_issue() {
			let issue_params = {
				item: this.item,
				learning_progression: this.lp_context,
				lp_unit: this.unit_context,
			}
			vapp.report_issue(issue_params)
		},

		add_to_open_lesson() {
			// this will only be called if we have a lesson open and being edited
			this.lp_context.shifted_for_lesson.$refs.lesson_editor_component.add_items_from_search([{value:this.item_to_show}])
		},
		
		assign_resource() {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.$prompt({
				title: 'New Student Activity',
				text: 'Enter a student-facing description for the linked resource:',
				initialValue: this.item.description,
				disableForEmptyValue: true,
				acceptText: 'Configure Activity',
				acceptIconAfter: 'fas fa-circle-arrow-right',
			}).then(link_description => {
				link_description = $.trim(link_description)
				if (empty(link_description)) return

				this.$emit('create_activity_from_resource', {type:'sparkl', starting_resource:this.item, link_description: link_description})
			}).catch(n=>{console.log(n)}).finally(f=>{})

			this.mark_as_last_viewed_resource()
		},

		mark_todo_assignment_complete() {
			if (this.is_required_video) {
				this.$alert('This resource is a video you must watch. Click the link and play the video; when the video completes playing you will be automatically marked as having completed the assignment.')
				return
			}

			if (!this.link_clicked) {
				this.$alert('You must click the link to open and interact with the resource before you can mark that you’ve completed it.')
				return
			}

			// console.log(this.user_info)
			// if (empty(this.user_info.todo_user_group) && !this.todo_user_group_warning_issued) {
			// 	this.show_tugc_dialog = true
			// 	// this.$store.commit('set', ['todo_user_group_warning_issued', true])
			// 	return
			// }

			this.$confirm({
			    title: 'Confirm Resource Completion',
			    text: sr('<div class="mb-2"><b>$1</b></div>By clicking the button below, you are confirming that you have completed this resource.', this.item.description),
			    acceptText: 'Confirm',
				acceptColor: 'pink accent-4',
				// dialogMaxWidth: 800
			}).then(y => {
				this.todo_complete = true
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
		complete_todo_assignment_clicked() {
			let msg = 'Are you sure you want to mark this resource as not complete?'
			if (this.is_required_video) {
				msg += ' You will have to re-watch the video in order to re-complete the resource assignment.'
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: msg,
			    acceptText: 'Mark as Not Complete',
			}).then(y => {
				this.todo_complete = false
				this.link_clicked = false
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
		save_video_progress(pct_complete) {
			// save the percent complete as the todo_status
			let payload = {resource_id: this.item.resource_id, todo_status: pct_complete}
			this.$store.dispatch('save_resource_completion', payload)
		},
		video_complete() {
			// this will trigger a save_resource_completion call in the todo_complete computed setter
			this.todo_complete = true
		},

		convert_lesson_to_lee() {
			this.convert_to_llm = true
			this.$store.commit('set', [this.lesson_being_edited, 'lp_variant_original', 'A'])
		},
		revert_lesson_to_original() {
			this.convert_to_llm = false
			this.$store.commit('set', [this.lesson_being_edited, 'lp_variant', 'A'])
		},

		show_llm_lesson_plan_description() { vapp.show_llm_lesson_plan_description() },
	}
}
</script>

<style lang="scss">
.k-resource-collection-item {
	margin:4px;
	background-color:#333;
	border-radius:10px;
	padding:1px;
	border:3px solid transparent;
	position:relative;
	cursor:pointer;
	height:calc(100% - 8px);
	display: flex;
}

.k-resource-collection-item-type-icon {
	float:right;
	text-align:center;
	height:18px;
	width:22px;
	margin:-1px -2px 0 2px;
	// position:absolute;
	// right:0;
	// top:4px;
}

.k-resource-collection-item-menu-btn {
	position:absolute;
	right:0;
	top:0;
	.fas { color:#fff!important; }
}

.k-resource-collection-item-todo-checkbox {
	position:absolute;
	right:32px;
	top:2px;
	cursor:pointer;
}

.k-resource-collection-item--title {
	// display:flex;
	// align-items: flex-start;
	padding:4px;
	font-size:14px;
	line-height:16px;
	text-align: left;
	width:100%;
	.k-resource-collection-item-title-link {
		// color:#111!important;
		color:#fff!important;
		font-weight:normal;
		text-decoration:none;
	}
	.k-resource-collection-item-title-link:hover {
		text-decoration:underline;
	}
}
.k-portal-collection-minimized .k-resource-collection-item--title {
	margin:0 12px;
}

.k-resource-collection-item-search-result {
	font-size:12px!important;
	margin-top:-1px;
	margin-left:2px;
	color:#fff!important;
}

.k-resource-collection-item-long-title .k-resource-collection-item--title { font-size:13px; line-height:15px; }
.k-resource-collection-item-really-long-title .k-resource-collection-item--title { font-size:12px; line-height:14px; }

.k-resource-collection-item-standards-wrapper {
	display:flex;
	flex-wrap:wrap;
}

.k-resource-collection-item-show-all-standards {
	.k-resource-collection-item--title {
		flex-wrap:wrap;
	}

	.k-resource-collection-item-standards-wrapper {
		margin-left:auto;
		justify-content: flex-end;
		.k-case-item-btn {
			margin-top:2px;
			margin-bottom:2px;
		}
	}
}

.k-resource-collection-item--stats {
	display:flex;
	justify-content: space-between;
	align-items: center;
	margin:4px 2px 2px 2px;
	padding:2px 0px 2px 8px;
	// color:$v-amber-darken-2;
	background-color:rgba(0,0,0,0.05);
	border-radius:4px;
	font-size:12px;
}

.k-resource-collection-item--info-header {
	// color:#666;
	font-size:14px;
	font-weight:bold;
}

.k-resource-collection-item--actions {
	display:flex;
	justify-content: space-between;
}

.k-resource-collection-item-more-info-card {
	border-width:5px;
	border-style:solid;
	// background-color:#eee;
	border-radius:10px!important;
}

.k-resource-collection-item--unit-planning-guide {
	background-color:$v-green-accent-4;
}

.v-application .k-resource-collection-imported-to-my-collections {
	border-color: rgba(0,0,0,0.6);
}

.v-application .k-resource-collection-item--last-viewed {
	border-color: $v-amber-accent-4;
}

.k-resource-collection-item-lesson-card-dialog, .k-resource-collection-item-quick-look-card-dialog {
	border-radius:12px!important;

	.k-resource-link-inline-html {
		max-width:760px;
		margin-left:auto;
		margin-right:auto;
	}
}

.k-resource-collection-lesson-item-card-dialog-shifted {
	position:fixed;
	left:auto;
	right:0.1vw;
	width:49.7vw;
	margin:0;
}

.k-resource-collection-quick-look-item-card-dialog-shifted {
	position:fixed;
	right:auto;
	left:0.1vw;
	width:49.7vw;
	margin:0;
}

.k-resource-collection-item-lesson-card {
	border-radius:12px!important;
	padding:12px;
}

.k-resource-collection-item-long-description {
	margin:12px 12px 0 12px;
	background-color:#f0f0f0;
	padding:8px;
	border-radius:8px;
	font-size:16px;
	line-height:21px;
}

.k-resource-collection-item-extensions {
	border:1px solid #999;
	padding:8px;
	margin:0 12px;
	font-size:16px;
	border-radius:8px;
}

.k-resource-link-iframe {
	width:100%;
	height:100%;
	border:0;
	background-color:#fff;
}

.k-resource-link-inline-html {
	background-color:#fff;
	border-radius:8px;
	padding:5px 15px 15px 15px;
	font-size:14px;
}

.k-aspect-ratio-box--10-8 {
	height: 0;
	overflow: hidden;
	padding-top: 10px / 8px * 100%;
	background: white;
	position: relative;
}
.k-aspect-ratio-box-inside {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.k-resource-link-iframe-outer.k-aspect-ratio-box--10-8 {
	// use this so the iframe won't be too tall when it's wide
	padding-top: min(76vh, 125%);
}

.k-resource-link-check-box {
	color:$v-pink-accent-1!important;
}
</style>
